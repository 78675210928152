import React, {useEffect, useState} from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useTheme} from "@mui/system";

export default function useExternalWebsiteDialog() {
  const [open, setOpen] = useState(false);
  const [currentLink, setCurrentLink] = useState<HTMLAnchorElement | null>(
    null,
  );
  const querySelector = ".external-website";

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    const handleClick = (event: Event) => {
      event.preventDefault();
      const link = event.currentTarget as HTMLAnchorElement;
      setCurrentLink(link);
      setOpen(true);
    };

    const attachListeners = () => {
      const links = document.querySelectorAll(querySelector);
      links.forEach((link) => {
        link.removeEventListener("click", handleClick); // Remove old listeners to prevent duplication
        link.addEventListener("click", handleClick);
      });
    };

    // Attach listeners initially
    attachListeners();

    // **Use MutationObserver to detect DOM changes**
    const observer = new MutationObserver(() => {
      attachListeners();
    });

    observer.observe(document.body, {childList: true, subtree: true});

    return () => {
      observer.disconnect();
      document.querySelectorAll(querySelector).forEach((link) => {
        link.removeEventListener("click", handleClick);
      });
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
    setCurrentLink(null);
  };

  const renderDialog = () => (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      fullScreen={!isDesktop}
    >
      <DialogTitle
        display={"flex"}
        alignItems={"center"}
        flexWrap={"wrap"}
        gap={isDesktop ? 2 : 1}
      >
        {currentLink?.dataset?.dialogTitle ?? "Website Preview"}
        <Grid marginLeft={"auto"} alignItems={"center"}>
          {currentLink?.dataset?.closeButtonLabel ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              sx={{
                textTransform: "none",
                maxHeight: "40px",
              }}
            >
              {currentLink.dataset.closeButtonLabel}
            </Button>
          ) : (
            <IconButton
              onClick={handleClose}
              sx={{position: "absolute", right: 8, top: 8}}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
      </DialogTitle>
      <iframe
        src={currentLink?.href ?? ""}
        width="100%"
        height={isDesktop ? window.innerHeight + "px" : "100%"}
        style={{border: "none"}}
        title="External Website"
      ></iframe>
    </Dialog>
  );

  return {renderDialog};
}
