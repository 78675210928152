import React, {useCallback} from "react";
import {Button, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ModalForm from "../Modal/components/modalForm";
import {t} from "i18next";

interface UseModalFormProps {
  handleCancel?: () => void;
  handleConfirm?: () => void;
  confirmText?: any;
  cancelText?: any;
  text?: any;
  isOpen: boolean;
  content?: any;
}

export const useModalForm = ({
  handleCancel,
  handleConfirm,
  text = t("modalForm.default.text"),
  confirmText = t("modalForm.default.confirm"),
  cancelText = t("modalForm.default.cancel"),
  isOpen,
  content,
}: UseModalFormProps) => {
  const renderModal = useCallback(() => {
    return (
      <ModalForm open={isOpen}>
        <Grid display={"flex"} flexDirection={"column"}>
          {content ?? (
            <Typography
              data-testid="modal-description"
              variant="subtitle1"
              component="h5"
            >
              {text}
            </Typography>
          )}

          <Grid>
            {handleCancel && (
              <Button
                sx={{mt: 2, mr: {xs: 1, md: 2}}}
                onClick={handleCancel}
                variant="outlined"
                color="primary"
                data-testid="modal-cancel"
              >
                {cancelText}
              </Button>
            )}
            {handleConfirm && (
              <Button
                sx={{mt: 2}}
                onClick={handleConfirm}
                variant="contained"
                color="secondary"
                data-testid="modal-confirm"
              >
                {confirmText}
              </Button>
            )}
          </Grid>
        </Grid>
      </ModalForm>
    );
  }, [
    isOpen,
    handleCancel,
    handleConfirm,
    text,
    confirmText,
    cancelText,
    content,
  ]);

  return {renderModal};
};
