import {Typography} from "@mui/material";
import {ErrorMessage} from "formik";
import React from "react";

export default function FormikErrorMessage({name}: {name: string}) {
  return (
    <ErrorMessage
      name={name}
      render={(msg) => (
        <Typography
          variant="caption"
          sx={{
            color: "error.main",
            display: "block",
            marginTop: "3px",
            marginX: "14px",
            fontSize: "12px",
          }}
        >
          {msg}
        </Typography>
      )}
    />
  );
}
