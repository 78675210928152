import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {CircularProgress, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {DeliveryMethodDto} from "../../../features/deliveryMethod/deliveryMethod-api";
import {useAppSelector} from "../../../store";
import DeliveryMethodCard from "./deliveryMethodCard";
import {IDeliveryMethod} from "./interfaces";
import {Trans} from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import {
  setDeliveryMethod,
  setOrderCharges,
} from "../../../features/order/parcelShipment-slice";
import {useGetShoppingQuote} from "../../hooks/useGetShoppingQuote";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";
import {mapI18LangToCulture} from "../../../common/utils/mapI18LangToCulture";
import i18next from "i18next";
import {GetQuoteForRateValues} from "../../../features/shipmentQuote/shipmentQuote-api";
import {
  ChargeDto,
  CommodityDto,
  CreateChargeCommand,
} from "../../../features/order/order-api";
import {useDeliveryHelper} from "../../hooks/useDeliveryHelper";
import {toastError} from "../../../common/utils/toastMessages";
import {customValueIsTrue} from "../../../../utils/helper.utils";

export default function DeliveryMethodList({setMethodIsSelected, config}: any) {
  const dispatch = useDispatch();
  const lang = i18next.language;

  const [deliveryMethods, setDeliveryMethods] = useState<IDeliveryMethod[]>([]);

  const {orderIds, currentOrder} = useDeliveryHelper();

  const getQuoteWorkflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows?.getQuoteWorkflowId,
  );

  const customerId = useAppSelector((state) => state.userState.contactId);

  const {getQuote} = useGetShoppingQuote();

  const [runWorkflow, {isLoading}] = useWorkflowExecutionMutation();

  const parseDeliveryMethods = (
    methodsData: DeliveryMethodDto[],
  ): IDeliveryMethod[] => {
    const isSelected = currentOrder?.deliveryMethod?.rateId > 0;
    setMethodIsSelected(isSelected);

    if (methodsData && methodsData.length > 0) {
      const deliveryMethods: IDeliveryMethod[] = methodsData?.map(
        (method: DeliveryMethodDto) => ({
          rateId: method.rateId,
          accountingItemId: method.accountingItemId,
          currencyId: method.currencyId,
          daysFrom: method.transitDaysMin,
          daysTo: method.transitDaysMax,
          deliveryType: {
            ...method.customValues,
          },
          isActive: method.rateId === currentOrder?.deliveryMethod?.rateId,
          isEnabled:
            (method.customValues!["is_pickup_location_rate"] == "true" &&
              currentOrder?.isPickupLocation === true) ||
            ((!("is_pickup_location_rate" in method.customValues!) ||
              method.customValues!["is_pickup_location_rate"] == "false") &&
              currentOrder?.isPickupLocation === false),
          modeOfTransportation: {
            modeOfTransportationId: method.modeOfTransportationId,
            description: method.modeOfTransportationName,
          },
          totalAmount: method.totalAmount,
          estimatedCost: method.estimatedCost,
        }),
      );

      if (
        !methodsData.some(
          (item) => item.rateId === currentOrder?.deliveryMethod?.rateId,
        )
      ) {
        setMethodIsSelected(false);
      }

      return deliveryMethods;
    } else {
      if (isSelected) {
        setMethodIsSelected(false);
      }
    }
    return [];
  };

  const handleClick = async (id?: number) => {
    const newDeliveryMethodsList = [...deliveryMethods];
    if (newDeliveryMethodsList.length > 0) {
      for (const deliveryMethod of newDeliveryMethodsList) {
        const index: number = newDeliveryMethodsList.indexOf(deliveryMethod);
        if (deliveryMethod.rateId === id) {
          const newDeliveryMethod = {
            ...deliveryMethod,
            isActive: true,
          };
          newDeliveryMethodsList[index] = newDeliveryMethod;
          dispatch(
            setDeliveryMethod({
              orderIds: orderIds,
              deliveryMethod: newDeliveryMethod,
            }),
          );

          // Update rate and rateId in charges
          if (currentOrder?.charges?.length > 0) {
            const values: GetQuoteForRateValues = {
              commodities: currentOrder?.containerCommodities?.map(
                (commodity: CommodityDto) => ({
                  ...commodity,
                  customValues: null,
                }),
              ),
              rateId: newDeliveryMethod?.rateId,
              additionalServices: {},
            };

            try {
              const response: any = await getQuote(values);

              const responseCharges = response?.data?.outputs?.quote?.charges;
              if (responseCharges) {
                let charges: ChargeDto[] = [];
                charges = [...charges, ...responseCharges];
                const chargesCommands = charges?.map((charge) => {
                  const command: CreateChargeCommand = {
                    organizationId: process.env
                      .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
                    values: charge,
                  };
                  return command;
                });
                const oldCharges =
                  currentOrder.charges as CreateChargeCommand[];
                const newCharges = oldCharges.map((charge) => {
                  if (
                    charge.values?.accountingItemCode ===
                    chargesCommands[0]?.values?.accountingItemCode
                  ) {
                    return {
                      ...charge,
                      values: {
                        ...charge.values,
                        rateId: chargesCommands[0].values?.rateId,
                        rate: chargesCommands[0].values?.rate,
                      },
                    };
                  }
                  return charge;
                });
                dispatch(
                  setOrderCharges({
                    orderIds: orderIds,
                    charges: newCharges,
                  }),
                );
              } else {
                console.log(response);
              }
            } catch (error: any) {
              console.log(error);
            }
          }
        } else {
          newDeliveryMethodsList[index] = {
            ...deliveryMethod,
            isActive: false,
          };
        }
      }
    }
    setDeliveryMethods(newDeliveryMethodsList);
    setMethodIsSelected(newDeliveryMethodsList.some((i) => i.isActive));
  };

  const countryCode = currentOrder?.consigneeAddressValues?.countryCode;

  // loading available delivery methods
  useEffect(() => {
    if (!countryCode) {
      return;
    }

    const isDangerousGoods =
      customValueIsTrue(
        currentOrder?.containerCommodities?.[0]?.customValues?.dangerousGoods,
      ) ||
      customValueIsTrue(
        currentOrder?.containerCommodities?.[0]?.customValues
          ?.lithiumBatteriesGoods,
      );

    const container = currentOrder?.containerCommodities?.[0];

    const workflowVariables: any = {
      customerId,
      countryCode: currentOrder?.consigneeAddressValues?.countryCode,
      postalCode: currentOrder?.consigneeAddressValues?.postalCode,
      additionalServices: null,
      commodities: [{...container, customValues: null}],
      isDangerousGoods,
      lang: mapI18LangToCulture(lang),
      promoCode: currentOrder?.customValues?.promoCode,
    };

    const executeQuoteWorkflowApiArgs: WorkflowExecutionApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      workflowId: getQuoteWorkflowId,
      values: {variables: {...workflowVariables}},
    };
    const fetchData = async () => {
      const response: any = await runWorkflow(executeQuoteWorkflowApiArgs);

      if (response.data?.outputs?.quotes) {
        const deliveryMethods = parseDeliveryMethods(
          response.data.outputs.quotes,
        );
        setDeliveryMethods(deliveryMethods);
      }

      if (response.error) {
        console.error(response);
        toastError(response);
      }
    };

    fetchData();
  }, [lang, countryCode, customerId]);

  return (
    <>
      {!deliveryMethods?.length && !isLoading && (
        <Grid sx={{display: "flex", justifyContent: "start", mt: 2}}>
          <Typography variant="body4">
            <Trans i18nKey="delivery.deliveryMethods.noDeliveryMethods">
              No delivery methods yet
            </Trans>
          </Typography>
        </Grid>
      )}
      {isLoading ? (
        <Grid sx={{display: "flex", justifyContent: "center", mt: 3}}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid spacing={3} container columns={{xs: 6, md: 7, lg: 6}}>
          {deliveryMethods?.map(
            (item: IDeliveryMethod, index: number) =>
              item.isEnabled &&
              item.modeOfTransportation?.modeOfTransportationId && (
                <Grid
                  onClick={() => !item.isActive && handleClick(item.rateId)}
                  key={item.rateId}
                  xs={6}
                  md={3.5}
                  lg={3}
                >
                  <DeliveryMethodCard deliveryMethod={item} index={index} />
                </Grid>
              ),
          )}
          {deliveryMethods?.filter((item) => item.isEnabled == false).length >
          0 ? (
            <Grid xs={6} md={7} lg={6} display={"flex"} alignItems={"center"}>
              <InfoIcon color="primary" />
              <Typography variant="caption1" ml={"4px"}>
                {currentOrder?.isPickupLocation === false ? (
                  <Trans i18nKey="delivery.deliveryMethods.addressIsDoor">
                    You have chosen delivery to your door. To use delivery to
                    the post office, select it in the delivery address section.
                  </Trans>
                ) : (
                  <Trans i18nKey="delivery.deliveryMethods.addressIsPickupLocation">
                    You have chosen delivery to the post office. To use delivery
                    to your door, select it in the delivery address section.
                  </Trans>
                )}
              </Typography>
            </Grid>
          ) : (
            <></>
          )}
          {deliveryMethods?.map(
            (item: IDeliveryMethod, index: number) =>
              !item.isEnabled &&
              item.modeOfTransportation?.modeOfTransportationId && (
                <Grid key={item.rateId} xs={6} md={3.5} lg={3}>
                  <DeliveryMethodCard deliveryMethod={item} index={index} />
                </Grid>
              ),
          )}
        </Grid>
      )}
    </>
  );
}
