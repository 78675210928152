import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../features/workflowTriggers/workflowExecution-api";
import {useCallback, useState} from "react";
import {toastError} from "../utils/toastMessages";
import {setUserContact} from "../../features/user/user-slice";

export default function useGetUserContact() {
  const dispatch = useDispatch();

  const getUserContactWorkflowId = useSelector(
    (state: RootState) =>
      state?.organizationConfigState?.workflows?.getUserContactWorkflowId,
  );
  const contact = useSelector((state: RootState) => state?.userState?.contact);

  const [runWorkflow] = useWorkflowExecutionMutation();
  const [isLoading, setIsLoading] = useState(false);

  const runGetContactWorkflow = useCallback(async () => {
    if (!getUserContactWorkflowId) {
      toastError("Missing workflow Id for getting user contact");
      return null;
    }

    if (isLoading) {
      return null; // Prevent duplicate calls
    }

    setIsLoading(true);

    try {
      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: Number(process.env.REACT_APP_PORTAL_ORGANIZATION_ID),
        workflowId: getUserContactWorkflowId,
        values: {},
      };

      const getWorkflowResult = await runWorkflow(
        executeWorkflowApiArgs,
      ).unwrap();
      const fetchedContact = getWorkflowResult?.outputs?.contact;

      if (!fetchedContact) {
        toastError("Error loading user contact information");
        return null;
      }

      dispatch(setUserContact(fetchedContact));
      return fetchedContact;
    } catch (error) {
      toastError("Failed to fetch user contact");
      console.error("Error fetching user contact:", error);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [getUserContactWorkflowId, runWorkflow, dispatch, isLoading]);

  const getContact = useCallback(async () => {
    return contact || runGetContactWorkflow();
  }, [runGetContactWorkflow, contact]);

  return {
    getContact, // Call this when user contact is needed
    reloadContact: runGetContactWorkflow,
    userContact: contact,
    isLoading, // Can be used to show a loading state
  };
}
