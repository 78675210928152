import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {CustomsDeclarationFragmentProps} from "./interfaces";
import {
  CommodityCustomValues,
  CommodityDto,
  CreateCommodityPortalCommand,
  DimensionsUnits,
  PurposeOfCommodityValues,
  UpdateCommodityPortalCommand,
  UpdateOrderPortalCommand,
  VolumeUnit,
  WeightUnits,
} from "../../../features/order/order-api";
import {Formik, FormikProps} from "formik";
import {Helmet} from "react-helmet";
import {useAppSelector} from "../../../store";
import {useDispatch} from "react-redux";
import uuid from "react-uuid";
import {setCommodity} from "../../../features/parcel/parcel-slice";
import DeclarationDetailsComponent from "./declarationDetailsComponent";
import {getPortalNameFromDomain} from "../../../common/OrganizationConfig/useOrganizationConfig";
import {useDeclarationValidationSchema} from "../pages/validation";

type CustomsDeclarationFormValues = UpdateOrderPortalCommand & {
  isGovernmentChecked: boolean;
};

export const initialCustomsDeclarationValues: CustomsDeclarationFormValues[] = [
  {
    trackingNumber: "",
    commodities: [],
    container: {
      weight: null,
      length: null,
      width: null,
      height: null,
      weightUnit: WeightUnits.Lb,
      dimensionsUnit: DimensionsUnits.In,
      volumeUnit: "Vlb",
      customValues: {
        dangerousGoods: false,
        lithiumBatteriesGoods: false,
        purposeOfCommodity: PurposeOfCommodityValues.Gift,
      },
    },
    orderEntities: [],
    charges: [],
    isGovernmentChecked: false,
  },
];

const getCommodityCustomValues = (
  commodities?: CommodityDto[] | null,
): CommodityCustomValues => {
  const commodityWithCustomValues = commodities?.find(
    (commodity) =>
      commodity?.customValues &&
      typeof commodity.customValues === "object" &&
      Object.keys(commodity.customValues).length > 0,
  );
  if (commodityWithCustomValues?.customValues) {
    return {
      ...commodityWithCustomValues.customValues,
    } as CommodityCustomValues;
  } else {
    return {
      purposeOfCommodity: PurposeOfCommodityValues.Gift,
      dangerousGoods: false,
      lithiumBatteriesGoods: false,
    } as CommodityCustomValues;
  }
};

export function DeclarationFragment({
  handleNext,
  pageTitle,
  config,
}: CustomsDeclarationFragmentProps) {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {t} = useTranslation();
  const [initialValue, setInitialValue] = useState<
    CustomsDeclarationFormValues[]
  >(initialCustomsDeclarationValues);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const orderData = useAppSelector((state: any) => state.parcelState);
  const {order} = orderData;

  const parcelValidationConfig = {
    ...config?.parcelValidation?.common,
    ...config?.parcelValidation[order?.consigneeAddressValues?.countryCode],
  };
  const {getDeclarationSchema} = useDeclarationValidationSchema();

  useEffect(() => {
    if (order && order.commodities && order.container) {
      const addedCommodity: CreateCommodityPortalCommand[] = [];
      order.commodities.forEach((item: CommodityDto & {uuid: string}) => {
        addedCommodity.push({
          description: item?.description,
          note: item?.note,
          quantity: item?.quantity,
          unitaryValue: item?.unitaryValue,
          uuid: item?.commodityId || item?.uuid || uuid(),
          customValues: item?.customValues ?? null,
          weight: item?.weight,
          pieces: item?.pieces,
          unit: item?.unit,
          commodityType: item?.commodityType,
          commodityTypeId: item?.commodityTypeId,
          containerCommodities: [],
          weightUnit: item?.weightUnit,
          dimensionsUnit: item?.dimensionsUnit,
          length: item?.length,
          width: item?.width,
          height: item?.height,
        });
      });

      const commodityWithCustomValues: CommodityCustomValues =
        getCommodityCustomValues([order?.container]);

      const containerInitialValues =
        initialCustomsDeclarationValues[0].container;
      const container: UpdateCommodityPortalCommand = {
        weight: order.container.weight,
        weightUnit:
          order.container.weightUnit ?? containerInitialValues?.weightUnit,
        width: order.container.width,
        height: order.container.height,
        length: order.container.length,
        unitaryValue: order.container.unitaryValue,
        dimensionsUnit:
          order.container.dimensionsUnit ??
          containerInitialValues?.dimensionsUnit,
        volumeUnit:
          order.container.volumeUnit ??
          (config?.defaultVolumeUnits as VolumeUnit) ??
          containerInitialValues?.volumeUnit,
        customValues: commodityWithCustomValues,
      };

      setInitialValue((prev) => {
        const values = [...prev];
        values[0] = {
          ...values[0],
          customValues: order?.customValues,
          trackingNumber: order?.trackingNumber ?? "",
          orderEntities: order?.orderEntities ?? [],
          commodities: addedCommodity,
          charges: order?.charges ?? [],
          container,
        };
        return values;
      });
      setIsLoading(false);
    }
  }, []);

  const handleOnSubmit = async (
    data: CustomsDeclarationFormValues[],
    isMainSubmit = false,
  ) => {
    const values = [...data];

    const customValues = {
      ...data[0].container?.customValues,
    };

    values.forEach((item) => {
      item.container = {
        ...data[0].container,
        pieces: 1,
        customValues,
      };

      if (
        item.commodities &&
        item.commodities.length === 1 &&
        item.commodities[0].description?.trim() === ""
      ) {
        item.commodities = [];
      }
    });
    dispatch(
      setCommodity({
        container: values[0].container,
        commodities: values[0].commodities || [],
      }),
    );
    if (isMainSubmit) handleNext();
  };

  return isLoading ? (
    <Grid sx={{display: "flex", justifyContent: "center", mt: 3}}>
      <CircularProgress />
    </Grid>
  ) : (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Formik
        initialValues={initialValue}
        onSubmit={(values: CustomsDeclarationFormValues[]) => {
          handleOnSubmit(values, true);
        }}
        validationSchema={(values: any) => {
          return getDeclarationSchema(
            t,
            process.env.REACT_APP_PORTAL_NAME ?? getPortalNameFromDomain(),
            parcelValidationConfig,
          );
        }}
      >
        {(formikProps: FormikProps<CustomsDeclarationFormValues[]>) => (
          <Grid container columns={{xs: 6, md: 12}}>
            <Grid md={12}>
              <Grid md={6} sx={{display: isDesktop ? "block" : "none"}}>
                <Typography variant={"body2"} fontSize={"1rem"} mb={2}>
                  <Trans i18nKey="delivery.declaration.notificationDeclaration">
                    Notice! Customs authorities require complete and accurate
                    item descriptions. Provide an accurate value. Customs
                    officials inspect the shipments entering and leaving the
                    country for compliance.
                  </Trans>
                </Typography>
                <Box
                  mb={4}
                  sx={{
                    border: "1px solid #2F81EF",
                    background: "#F5F9FF",
                    borderRadius: "100px",
                    padding: "1rem 2rem 1rem 2rem",
                  }}
                >
                  <Typography
                    textAlign={"start"}
                    variant="body2"
                    fontSize={"1rem"}
                  >
                    <Trans i18nKey="delivery.declaration.exampleDeclaration">
                      Example. Descriptions Parts, Gifts, Textiles or Samples
                      are too vague and can`t be accepted. Description Woman`s
                      T-Shirts made of cotton is correct.
                    </Trans>
                  </Typography>
                </Box>
              </Grid>
              <Grid>
                <DeclarationDetailsComponent
                  saveToStorage={(values: CustomsDeclarationFormValues[]) => {
                    handleOnSubmit(values);
                  }}
                  purchases={initialValue}
                  config={config}
                />
              </Grid>
              <Grid mt={isDesktop ? 0 : 4}>
                <Grid mb={4}>
                  <Grid md={2}>
                    <LoadingButton
                      data-testid="btn-continue-customs-declaration"
                      sx={{mt: 3, mb: 1}}
                      fullWidth
                      type="submit"
                      variant="contained"
                      form="addCustomValuesCommodityForm"
                      color="secondary"
                      loadingPosition="end"
                      endIcon={<></>}
                      disabled={
                        formikProps.isSubmitting ||
                        formikProps.values!.some(
                          (item) =>
                            item &&
                            item.commodities &&
                            item.commodities.length! < 1,
                        )
                      }
                      loading={formikProps.isSubmitting}
                    >
                      <Trans i18nKey="btnContinue">Continue</Trans>
                    </LoadingButton>
                  </Grid>
                  <Grid
                    display={
                      formikProps.values!.some(
                        (item) =>
                          item &&
                          item.commodities &&
                          item.commodities.length! < 1,
                      )
                        ? "flex"
                        : "none"
                    }
                  >
                    <Typography
                      variant="body1"
                      color={"red"}
                      mt={1}
                      fontSize={"14px"}
                    >
                      <Trans i18nKey={"validation.declaration.packageNoItems"}>
                        Package must contain at least one item!
                      </Trans>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
}
