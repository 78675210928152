import {basePortalApi as api} from "../basePortalApi";
import {ContactDto} from "../contact/contact-api";
import {ModeOfTransportationDto} from "../modeOfTransportation/modeOfTransportation-api";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    deliveryMethodsGetDeliveryMethods: build.query<
      DeliveryMethodsGetDeliveryMethodsApiResponse,
      DeliveryMethodsGetDeliveryMethodsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/deliveryMethods/country/${queryArg.countryCode}/getDeliveryMethods`,
        body: queryArg.values,
        method: "POST",
      }),
    }),
    deliveryMethodsGetRates: build.mutation<
      DeliveryMethodsGetDeliveryMethodsApiResponse,
      DeliveryMethodsGetDeliveryMethodsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/deliveryMethods/country/${queryArg.countryCode}/getDeliveryMethods`,
      }),
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type DeliveryMethodsGetDeliveryMethodsApiResponse =
  /** status 200  */ DeliveryMethodDto[];
export type DeliveryMethodsGetDeliveryMethodsApiArg = {
  organizationId: number;
  countryCode?: string | null;
  values: GetDeliveryMethodsCommandValues;
};
export type LinkDto = {
  href?: string | null;
  rel?: string | null;
  type?: string | null;
  templated?: boolean | null;
};
export type DeliveryMethodDto = {
  rateId: number;
  organizationId: number;
  accountingItemId?: number | null;
  amendmendNumber?: string | null;
  applyToDestinationCountry?: boolean | null;
  applyToOriginCountry?: boolean | null;
  automaticallyCreateCharge?: boolean;
  carrierId?: number | null;
  carrier?: ContactDto | null;
  clientId?: number | null;
  commodityTypeId?: number | null;
  contractNumber?: string | null;
  countryOfDestinationCode?: string | null;
  countryOfOriginCode?: string | null;
  currencyId?: number | null;
  effectiveDate?: Date | null;
  expirationDate?: Date | null;
  frequency?: any;
  isHazardous?: boolean | null;
  modeOfTransportationId?: number | null;
  modeOfTransportationName?: string | null;
  modeOfTransportation?: ModeOfTransportationDto | null;
  notes?: string | null;
  portOfDeliveryId?: string | null;
  portOfLoadingId?: string | null;
  portOfReceiptId?: string | null;
  portOfUnloadingId?: string | null;
  rateType?: any;
  serviceType?: any;
  tariff?: TariffOptions;
  transitDaysMin?: number | null;
  transitDaysMax?: number | null;
  created?: Date;
  createdBy?: string | null;
  lastModified?: Date;
  lastModifiedBy?: string | null;
  createdByUserName?: string | null;
  lastModifiedByUserName?: string | null;
  customValues?: {
    [key: string]: string;
  } | null;
  links?: LinkDto[] | null;
  totalAmount?: number | null;
  estimatedCost?: string | null;
};

export type GetDeliveryMethodsCommandValues = {
  isDangerousGoods?: boolean;
};

export type TariffOptions = {
  maximum?: number | null;
  minimum?: number | null;
  rateMultiplier?: number | null;
  applyBy?: any;
  unitType?: Units;
  ratePerType?: RatePerType;
  ratePer?: number | null;
  rates?: number[][] | null;
  rateData?: RateData[];
};

export type RateData = {
  rateIndex?: number | undefined;
  rateValue: number;
};

export enum Units {
  Ft = "Ft",
  Vlb = "Vlb",
  Vkg = "Vkg",
  M = "M",
  Lb = "Lb",
  Kg = "Kg",
}

export enum RatePerType {
  Unit = "Unit",
  Range = "Range",
}

export const {
  useDeliveryMethodsGetDeliveryMethodsQuery,
  useDeliveryMethodsGetRatesMutation,
} = injectedRtkApi;
