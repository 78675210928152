import {useModalForm} from "../../../common/hooks/useModalForm";
import {useCallback, useState} from "react";
import {UserContactAddressInfo} from "../../../UserProfile/AccountSavedAddresses/components";
import {AddressCardValues} from "../components/interfaces";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {customValueIsTrue} from "../../../../utils/helper.utils";
import {t} from "i18next";

export interface UseContactAddressEmailMissingProps {
  editAddressAction?: any;
}

export function useContactAddressEmailMissing({
  editAddressAction,
}: UseContactAddressEmailMissingProps = {}) {
  const enableEmailMissingValidation = useSelector((state: RootState) =>
    customValueIsTrue(
      state?.organizationConfigState?.modules?.address
        ?.enableEmailMissingValidation,
    ),
  );

  const [isOpen, setIsOpen] = useState(false);

  const i18nPrefix = "delivery.emailMissing.";

  const {renderModal} = useModalForm({
    isOpen: isOpen,
    handleConfirm: () => {
      if (editAddressAction) {
        editAddressAction();
      }
      setIsOpen(false);
    },
    handleCancel: () => setIsOpen(false),
    confirmText: t(`${i18nPrefix}editAddress`),
    content: t(`${i18nPrefix}modalTitle`),
  });

  const isContactAddressEmailMissing = useCallback(
    (
      contactAddress:
        | (UserContactAddressInfo & AddressCardValues)
        | null
        | undefined,
    ) => {
      if (!contactAddress || !enableEmailMissingValidation) {
        return false;
      }

      if (contactAddress.isPickupLocation) {
        return !contactAddress.pickupLocationEmail;
      } else {
        return !contactAddress.toAddressEmail;
      }
    },
    [enableEmailMissingValidation],
  );

  return {
    renderEmailMissingModal: renderModal,
    isEmailMissingModalOpen: isOpen,
    setIsEmailMissingModalOpen: setIsOpen,
    isContactAddressEmailMissing,
    i18nPrefixEmailMissing: i18nPrefix,
  };
}
