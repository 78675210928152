import React, {useEffect, useMemo, useState} from "react";
import {Trans} from "react-i18next";
import {useDispatch} from "react-redux";
import {Box, Button, CircularProgress} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {setDeliveryAddress} from "../../../features/parcel/parcel-slice";
import {RootState, useAppSelector} from "../../../store";
import {
  AddressCardValues,
  ContactCategory,
  DeliveryAddressFragmentProps,
} from "./interfaces";
import {
  ContactAddressDto,
  useLazyContactAddressGetContactAddressQuery,
} from "../../../features/contactAddress/contactAddress-api";
import AddressCard from "../../../common/components/addressCard";
import {UserContactAddressInfo} from "../../../UserProfile/AccountSavedAddresses/components";
import DeliveryAddressForm from "./deliveryAddressForm";
import {PageFragments} from "../../../UserProfile/AccountSavedAddresses/pages";
import {toastError} from "../../../common/utils/toastMessages";
import {Helmet} from "react-helmet";
import {getLangCodeFromI18n} from "../../../app/app.component";
import {getLocalizedToponyms} from "../../../ParcelShipments/AllParcelShipments/utils/parcelShipmentTable.utils";
import {useModalForm} from "../../../common/hooks/useModalForm";
import {isContactAddressPassportRequired} from "./deliveryAddressFragment";
import {useContactAddressEmailMissing} from "../hooks/useContactAddressEmailMissing";
import {t} from "i18next";

export const userContactAddressInfoInitialValues: UserContactAddressInfo = {
  toAddressFirstName: "",
  toAddressLastName: "",
  toAddressPhoneNumber: "",
  toAddressEmail: "",
  toAddressCountryCode: "",
  toAddressCountryName: "",
  toAddressRegionCode: "",
  toAddressRegionName: "",
  toAddressDistrict: "",
  toAddressCity: "",
  toAddressPostalCode: "",
  toAddressStreetName: "",
  toAddressHouseNumber: "",
  toAddressApartment: "",
  toAddressSetAsDefault: false,
  toAddressContactCategory: ContactCategory.Individual,
  toAddressCompanyName: "",
  pickupLocationFirstName: "",
  pickupLocationLastName: "",
  pickupLocationPhoneNumber: "",
  pickupLocationEmail: "",
  pickupLocationCountryCode: "",
  pickupLocationCountryName: "",
  pickupLocationContactAddressId: null,
  pickupLocationPostOffices: true,
  pickupLocationParcelLockers: true,
  pickupLocationAddressLine: "",
  pickupLocationDistrict: "",
  pickupLocationContactCategory: ContactCategory.Individual,
  pickupLocationCompanyName: "",
  contactId: null,
  contactAddressId: null,
  addressLine: "",
  isPickupLocation: null,
};

export default function ParcelDeliveryAddressFragment({
  handleNext,
  pageTitle,
  config,
}: DeliveryAddressFragmentProps) {
  const lang = getLangCodeFromI18n();
  const dispatch = useDispatch();
  const orderData = useAppSelector((state: any) => state.parcelState);

  const [editingWidth, setEditingWidth] = useState<boolean>(false);
  const [pageFragment, setPageFragment] = useState<PageFragments>(3);
  const [editingAddress, setEditingAddress] =
    useState<UserContactAddressInfo | null>(null);
  const [currentDeliveryAddressId, setCurrentDeliveryAddressId] =
    useState<number | null>(null);
  const [currentDeliveryAddress, setCurrentDeliveryAddress] =
    useState<(UserContactAddressInfo & AddressCardValues) | null | undefined>(
      undefined,
    );
  const [selectedAddressIsLoading, setSelectedAddressIsLoading] =
    useState<boolean>(true);

  const [getContactAddressQuery] =
    useLazyContactAddressGetContactAddressQuery();

  const getContactAddress = async (
    contactAddressId: number,
  ): Promise<ContactAddressDto | null> => {
    try {
      const response = await getContactAddressQuery({
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        contactAddressId: contactAddressId,
      });

      if (response?.error || !response?.data) {
        toastError(response?.error || "Failed to get selected contact address");
        return null;
      }

      return response.data;
    } catch (error) {
      toastError(error as Error);
      return null;
    }
  };

  const setCurrentDeliveryAddressFormDto = (
    contactAddress?: ContactAddressDto | null,
  ) => {
    if (contactAddress) {
      const [
        localizedCountryName,
        localizedStateName,
        localizedCityName,
        localizedAddressName,
      ] = getLocalizedToponyms(contactAddress, lang);

      setCurrentDeliveryAddress({
        ...userContactAddressInfoInitialValues,
        ...{
          contactId: contactAddress.contactId,
          name:
            contactAddress?.contact?.customValues?.contactCategory ===
            ContactCategory.Business
              ? contactAddress?.contact?.name
              : `${contactAddress.contact?.contactFirstName} ${contactAddress.contact?.contactLastName}`,
          contactName: `${contactAddress.contact?.contactFirstName} ${contactAddress.contact?.contactLastName}`,
          addressLine1: localizedAddressName,
          addressLine2: contactAddress.addressLine2 ?? "",
          houseNumber: contactAddress.customValues?.toAddressHouseNumber ?? "",
          city: localizedCityName,
          district: contactAddress.customValues?.toAddressDistrict ?? null,
          region: localizedStateName,
          postalCode: contactAddress.postalCode,
          country: localizedCountryName,
          countryCustomValues: contactAddress?.country?.customValues,
          phoneNumber: contactAddress.contact?.phoneNumber,
          contactCustomValues: contactAddress?.contact?.customValues,
        },
        ...(contactAddress.customValues?.is_pickup_location
          ? {
              pickupLocationFirstName:
                contactAddress.contact?.contactFirstName ?? "",
              pickupLocationLastName:
                contactAddress.contact?.contactLastName ?? "",
              pickupLocationPhoneNumber:
                contactAddress.contact?.phoneNumber ?? "",
              pickupLocationEmail: contactAddress.contact?.emailAddress ?? "",
              pickupLocationCountryCode: contactAddress.countryCode ?? "",
              pickupLocationCountryName: localizedCountryName ?? "",
              pickupLocationContactAddressId:
                contactAddress.contactAddressId ?? null,
              pickupLocationAddressLine: localizedAddressName ?? "",
              contactAddressId: contactAddress.contactAddressId ?? null,
              addressLine: localizedAddressName ?? "",
              pickupLocationDistrict:
                contactAddress.customValues?.toAddressDistrict ?? "",
              isPickupLocation: true,
              pickupLocationContactCategory: contactAddress.contact
                ?.customValues?.contactCategory as ContactCategory,
              pickupLocationCompanyName: contactAddress.contact?.name ?? "",
            }
          : {
              toAddressFirstName:
                contactAddress.contact?.contactFirstName ?? "",
              toAddressLastName: contactAddress.contact?.contactLastName ?? "",
              toAddressPhoneNumber: contactAddress.contact?.phoneNumber ?? "",
              toAddressEmail: contactAddress.contact?.emailAddress ?? "",
              toAddressCountryCode: contactAddress.countryCode ?? "",
              toAddressCountryName: localizedCountryName ?? "",
              toAddressRegionCode: contactAddress.stateCode ?? "",
              toAddressRegionName: localizedStateName ?? "",
              toAddressDistrict:
                contactAddress.customValues?.toAddressDistrict ?? "",
              toAddressCity: contactAddress.cityName ?? "",
              toAddressPostalCode: contactAddress.postalCode ?? "",
              toAddressStreetName: contactAddress.addressLine ?? "",
              toAddressHouseNumber:
                contactAddress.customValues?.toAddressHouseNumber ?? "",
              toAddressApartment:
                contactAddress.customValues?.toAddressApartment ?? "",
              toAddressSetAsDefault: false,
              contactAddressId: contactAddress.contactAddressId ?? null,
              addressLine: contactAddress.addressLine ?? "",
              isPickupLocation: false,
              toAddressContactCategory: contactAddress.contact?.customValues
                ?.contactCategory as ContactCategory,
              toAddressCompanyName: contactAddress.contact?.name ?? "",
            }),
      });
    } else {
      setCurrentDeliveryAddress(contactAddress);
    }
  };

  const handleEditAddressClick = () => {
    if (currentDeliveryAddress) {
      onEditClick(currentDeliveryAddress);
    }
  };

  const {
    renderEmailMissingModal,
    isContactAddressEmailMissing,
    setIsEmailMissingModalOpen,
    i18nPrefixEmailMissing,
  } = useContactAddressEmailMissing({
    editAddressAction: handleEditAddressClick,
  });

  const passportRequired =
    useAppSelector(
      (state: RootState) =>
        state?.organizationConfigState?.modules?.delivery?.passportRequired,
    ) === true;

  const isContactAddressPassportRequiredValue = useMemo(() => {
    return isContactAddressPassportRequired(
      currentDeliveryAddress,
      passportRequired,
    );
  }, [currentDeliveryAddress, passportRequired]);

  const [isUploadPassportModalOpen, setIsUploadPassportModalOpen] =
    useState(false);

  const i18nPassportPrefix = "delivery.passport.";

  const {renderModal: renderUploadPassportModal} = useModalForm({
    handleCancel: () => {
      setIsUploadPassportModalOpen(false);
    },
    handleConfirm: () => {
      handleEditAddressClick();
      setIsUploadPassportModalOpen(false);
    },
    text: (
      <Trans i18nKey={i18nPassportPrefix + "requirement"}>
        Please upload Receiver’s passport scan or photo
        <br />
        to simplify customs clearance in the destination country
      </Trans>
    ),
    confirmText: <Trans i18nKey={i18nPassportPrefix + "upload"}>Upload</Trans>,
    cancelText: <Trans i18nKey={i18nPassportPrefix + "cancel"}>Cancel</Trans>,
    isOpen: isUploadPassportModalOpen && passportRequired,
  });

  useEffect(() => {
    if (orderData?.order?.consigneeAddressValues?.contactAddressId) {
      setSelectedAddressIsLoading(true);
      getContactAddress(
        orderData?.order?.consigneeAddressValues?.contactAddressId,
      )
        .then((contactAddress) => {
          if (contactAddress) {
            setCurrentDeliveryAddressFormDto(contactAddress);
          } else {
            setCurrentDeliveryAddress(null);
          }
        })
        .finally(() => {
          setSelectedAddressIsLoading(false);
        });
    } else {
      setCurrentDeliveryAddress(null);
    }
    setSelectedAddressIsLoading(false);
  }, []);

  useEffect(() => {
    if (
      document.title.toLowerCase().includes("delivery address") &&
      (currentDeliveryAddressId ||
        orderData?.order?.consigneeAddressValues?.contactAddressId)
    ) {
      getContactAddress(
        currentDeliveryAddressId ??
          orderData?.order?.consigneeAddressValues?.contactAddressId,
      ).then((contactAddress) => {
        if (contactAddress) {
          setCurrentDeliveryAddressFormDto(contactAddress);
        }
      });
    }
  }, [currentDeliveryAddressId, lang]);

  useEffect(() => {
    saveCurrentDeliveryAddressToStorage();
  }, [currentDeliveryAddress]);

  const saveCurrentDeliveryAddressToStorage = () => {
    if (currentDeliveryAddress) {
      const savingContactAddress = setDeliveryAddress({
        consignee: currentDeliveryAddress.pickupLocationContactAddressId
          ? {
              contactId: currentDeliveryAddress.contactId,
              firstName: currentDeliveryAddress.pickupLocationFirstName,
              lastName: currentDeliveryAddress.pickupLocationLastName,
              phoneNumber: currentDeliveryAddress.pickupLocationPhoneNumber,
              emailAddress: "",
            }
          : {
              contactId: currentDeliveryAddress.contactId,
              firstName: currentDeliveryAddress.toAddressFirstName,
              lastName: currentDeliveryAddress.toAddressLastName,
              phoneNumber: currentDeliveryAddress.toAddressPhoneNumber,
              emailAddress: currentDeliveryAddress.toAddressEmail,
            },
        consigneeAddress: currentDeliveryAddress.pickupLocationContactAddressId
          ? {
              contactAddressId:
                currentDeliveryAddress.pickupLocationContactAddressId,
              countryCode: currentDeliveryAddress.pickupLocationCountryCode,
              countryName: currentDeliveryAddress.pickupLocationCountryName,
              district: currentDeliveryAddress.pickupLocationDistrict,
              regionName: currentDeliveryAddress.region,
              city: currentDeliveryAddress.city,
              postalCode: currentDeliveryAddress.postalCode,
              streetName: currentDeliveryAddress.pickupLocationAddressLine,
              apartment: currentDeliveryAddress.addressLine2,
              isPickupLocation: currentDeliveryAddress.isPickupLocation,
            }
          : {
              contactAddressId: currentDeliveryAddress.contactAddressId,
              countryCode: currentDeliveryAddress.toAddressCountryCode,
              countryName: currentDeliveryAddress.toAddressCountryName,
              regionCode: currentDeliveryAddress.toAddressRegionCode,
              regionName: currentDeliveryAddress.toAddressRegionName,
              district: currentDeliveryAddress.toAddressDistrict,
              city: currentDeliveryAddress.toAddressCity,
              postalCode: currentDeliveryAddress.toAddressPostalCode,
              streetName: currentDeliveryAddress.toAddressStreetName,
              houseNumber: currentDeliveryAddress.toAddressHouseNumber,
              apartment: currentDeliveryAddress.addressLine2,
            },
      });

      dispatch(savingContactAddress);
    }
  };

  const onClickContinue = () => {
    if (currentDeliveryAddress) {
      if (
        isContactAddressPassportRequired(
          currentDeliveryAddress,
          passportRequired,
        )
      ) {
        setIsUploadPassportModalOpen(true);
        return;
      }
      if (isContactAddressEmailMissing(currentDeliveryAddress)) {
        if (currentDeliveryAddress.isPickupLocation) {
          toastError(t(`${i18nPrefixEmailMissing}action`));
        } else {
          setIsEmailMissingModalOpen(true);
        }
        return;
      }
      saveCurrentDeliveryAddressToStorage();
      handleNext();
    }
  };

  const onEditClick = (
    currentDeliveryAddress: UserContactAddressInfo | null,
  ) => {
    setCurrentDeliveryAddress(null);
    setEditingAddress(currentDeliveryAddress);
    setPageFragment(currentDeliveryAddress?.isPickupLocation ? 6 : 4);
  };

  const onChooseAnotherClick = () => {
    setCurrentDeliveryAddress(null);
    setPageFragment(1);
  };

  const onChangePageState = (pageFragment: PageFragments) => {
    setPageFragment(pageFragment);
  };

  return selectedAddressIsLoading || currentDeliveryAddress === undefined ? (
    <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
      <CircularProgress />
    </Box>
  ) : currentDeliveryAddress ? (
    <Grid sx={{pt: 2}} container columns={{xs: 6, md: 12}}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Grid xs={6} md={4} lg={3}>
        <AddressCard
          name={currentDeliveryAddress.name}
          contactName={currentDeliveryAddress.contactName}
          phoneNumber={currentDeliveryAddress.phoneNumber}
          addressLine1={currentDeliveryAddress.addressLine1}
          addressLine2={currentDeliveryAddress.addressLine2}
          houseNumber={currentDeliveryAddress.houseNumber}
          region={currentDeliveryAddress.region}
          city={currentDeliveryAddress.city}
          country={currentDeliveryAddress.country}
          district={currentDeliveryAddress.district}
          postalCode={currentDeliveryAddress.postalCode}
          onEditClick={handleEditAddressClick}
          onChooseAnotherClick={() => onChooseAnotherClick()}
          isDesktop={true}
          isDeliveryAddress={true}
          isPickupLocation={currentDeliveryAddress.isPickupLocation}
          isHoverDisabled={true}
          isPassportRequired={isContactAddressPassportRequiredValue}
          isEmailMissing={isContactAddressEmailMissing(currentDeliveryAddress)}
        />
        <Grid sx={{py: {xs: 2, md: 5}}} md={7} xs={6}>
          <Button
            data-testid="btn-continue-delivery-address"
            fullWidth
            type="button"
            variant="contained"
            color="secondary"
            onClick={onClickContinue}
            sx={{
              p: 1,
              mb: 3,
            }}
          >
            <Trans i18nKey="btnContinue">Continue</Trans>
          </Button>
        </Grid>
      </Grid>
      {renderUploadPassportModal()}
      {renderEmailMissingModal()}
    </Grid>
  ) : (
    <Grid sx={{paddingBottom: 7}} container columns={{xs: 6, md: 12}}>
      <Grid lg={12} xl={editingWidth ? 9 : 12}>
        <DeliveryAddressForm
          config={config}
          pageFragment={pageFragment}
          editingAddress={editingAddress}
          setCurrentDeliveryAddress={setCurrentDeliveryAddress}
          setCurrentDeliveryAddressId={setCurrentDeliveryAddressId}
          setEditingWidth={setEditingWidth}
          onChangePageState={onChangePageState}
          setEditingAddress={setEditingAddress}
          setCurrentDeliveryAddressFormDto={setCurrentDeliveryAddressFormDto}
          getContactAddress={getContactAddress}
        />
      </Grid>
    </Grid>
  );
}
