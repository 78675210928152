import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../features/workflowTriggers/workflowExecution-api";
import {useCallback, useState} from "react";
import {toastError} from "../utils/toastMessages";
import {getPortalNameFromDomain} from "../OrganizationConfig/useOrganizationConfig";

export enum UserAddressType {
  Delivery = "delivery",
  Shipping = "shipping",
  All = "all",
}

export type UseGetUserAddressesProps = {
  addressType: UserAddressType;
};

export default function useGetUserAddresses({
  addressType,
}: UseGetUserAddressesProps) {
  const dispatch = useDispatch();

  const getUserDeliveryAddressesWorkflowId = useSelector(
    (state: RootState) =>
      state?.organizationConfigState?.workflows
        ?.getUserDeliveryAddressesWorkflowId,
  );

  const [runWorkflow] = useWorkflowExecutionMutation();
  const [isLoading, setIsLoading] = useState(false);

  const runGetAddressesWorkflow = useCallback(async () => {
    if (!getUserDeliveryAddressesWorkflowId) {
      toastError("Missing workflow Id for getting user delivery addresses");
      return null;
    }

    if (isLoading) {
      return null; // Prevent duplicate calls
    }

    setIsLoading(true);

    try {
      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: Number(process.env.REACT_APP_PORTAL_ORGANIZATION_ID),
        workflowId: getUserDeliveryAddressesWorkflowId,
        values: {
          variables: {
            portalName:
              process.env.REACT_APP_PORTAL_NAME ?? getPortalNameFromDomain(),
            addressType,
          },
        },
      };

      const getWorkflowResult = await runWorkflow(
        executeWorkflowApiArgs,
      ).unwrap();
      const fetchedDeliveryAddresses =
        getWorkflowResult?.outputs?.contactAddresses;

      if (!fetchedDeliveryAddresses) {
        toastError("Error loading user delivery addresses");
        return null;
      }
      return fetchedDeliveryAddresses;
    } catch (error) {
      toastError("Failed to fetch delivery addresses");
      console.error("Error fetching delivery addresses:", error);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [getUserDeliveryAddressesWorkflowId, runWorkflow, dispatch, isLoading]);

  return {
    getDeliveryAddresses: runGetAddressesWorkflow,
    isLoading, // Can be used to show a loading state
  };
}
