import {$enum} from "ts-enum-util";
import * as yup from "yup";
import {addMethod, string} from "yup";
import {PurposeOfCommodityValues} from "../../../features/order/order-api";
import {useParcelValidation} from "../hooks/useParcelValidation";
import {useCallback} from "react";
import {useCountryProhibitedKeywordsValidation} from "../hooks/useCountryProhibitedKeywordsValidation";
import i18n, {t} from "i18next";
import {
  customValueIsTrue,
  getCustomValue,
} from "../../../../utils/helper.utils";

const onlyNumeric = /^\d*\.?\d*$/;
const positiveInteger = /^[1-9]\d*$/;

declare module "yup" {
  interface StringSchema {
    validateCountryProhibitedKeywords(message?: string): StringSchema;
  }
}

export const useDeclarationValidationSchema = () => {
  const {validateParcel, handleParcelValidationMessage} = useParcelValidation();

  const lang = i18n.language;

  const {
    validateText: validateTextCountryProhibitedKeywords,
    getInvalidKeywordsFromText: getInvalidCountryKeywordsFromText,
    country,
  } = useCountryProhibitedKeywordsValidation();

  addMethod(
    string,
    "validateCountryProhibitedKeywords",
    function validateCountryProhibitedKeywords(
      i18nKey = t("validation.declaration.countryProhibitedKeywords"),
    ) {
      return this.test({
        name: "countryProhibitedKeywords",
        message: (data) => {
          return t(i18nKey, {
            keywords:
              getInvalidCountryKeywordsFromText(data?.value)?.join(", ") ?? "",
            countryName:
              getCustomValue(
                country?.customValues,
                `to_country_translation_${lang}`,
              ) ??
              getCustomValue(
                country?.customValues,
                `country_translation_${lang}`,
              ) ??
              country?.name,
          });
        },
        test: (value) => {
          return validateTextCountryProhibitedKeywords(value);
        },
      });
    },
  );

  const testNumberGreaterThanZero = useCallback((stringValue: any) => {
    const number = Number(stringValue);
    return !!(number && number > 0);
  }, []);

  const getCommodityDeclarationSchema = useCallback(
    (t: any, portalName: string) => {
      const isEnglish = (value: any) => /^[^\u0400-\u04FF]+$/u.test(value);
      const commodityIsRemoved = (commodity: any) => {
        return commodity?.isRemoved === true;
      };

      let schema = null;
      switch (portalName) {
        case "portal":
        default:
          schema = yup.object().when({
            is: commodityIsRemoved,
            then: yup.object(),
            otherwise: yup.object().shape({
              description: yup
                .string()
                .required(t("validation.editPurchases.descriptionRequired"))
                .max(700, t("validation.editPurchases.descriptionLength"))
                .test(
                  "is-english",
                  t("validation.editPurchases.descriptionLanguage"),
                  isEnglish,
                )
                .validateCountryProhibitedKeywords(),
              note: yup
                .string()
                .max(700, t("validation.editPurchases.noteLength")),
              quantity: yup
                .string()
                .typeError(t("validation.declaration.quantityPositiveInteger"))
                .matches(
                  positiveInteger,
                  t("validation.declaration.quantityPositiveInteger"),
                )
                .required(t("validation.declaration.quantityRequired")),
              weight: yup
                .string()
                .matches(onlyNumeric, t("validation.code.digits")),
              commodityType: yup
                .object()
                .required(t("validation.declaration.categoryRequired"))
                .shape({
                  code: yup
                    .string()
                    .nullable()
                    .required(t("validation.declaration.categoryRequired")),
                  description: yup
                    .string()
                    .nullable()
                    .validateCountryProhibitedKeywords(),
                  customValues: yup.object().shape({
                    [`simplified_description_${lang}`]: yup
                      .string()
                      .nullable()
                      .validateCountryProhibitedKeywords(),
                  }),
                }),
              unitaryValue: yup
                .string()
                .required(t("validation.declaration.unitaryValueRequired"))
                .matches(onlyNumeric, t("validation.code.digits")),
            }),
          });
          break;
        case "parcels":
          schema = yup.object().shape({
            description: yup
              .string()
              .required(t("validation.editPurchases.descriptionRequired"))
              .max(700, t("validation.editPurchases.descriptionLength"))
              .test(
                "is-english",
                t("validation.editPurchases.descriptionLanguage"),
                isEnglish,
              )
              .validateCountryProhibitedKeywords(),
            note: yup
              .string()
              .max(700, t("validation.editPurchases.noteLength")),
            quantity: yup
              .string()
              .typeError(t("validation.declaration.quantityPositiveInteger"))
              .matches(
                positiveInteger,
                t("validation.declaration.quantityPositiveInteger"),
              )
              .required(t("validation.declaration.quantityRequired")),
            unitaryValue: yup
              .string()
              .required(t("validation.declaration.unitaryValueRequired"))
              .matches(onlyNumeric, t("validation.code.digits")),
            weight: yup
              .string()
              .matches(onlyNumeric, t("validation.code.digits")),
            commodityType: yup.object().shape({
              code: yup
                .string()
                .nullable()
                .required(t("validation.declaration.categoryRequired")),
              description: yup
                .string()
                .nullable()
                .validateCountryProhibitedKeywords(),
              customValues: yup.object().shape({
                [`simplified_description_${lang}`]: yup
                  .string()
                  .nullable()
                  .validateCountryProhibitedKeywords(),
              }),
            }),
          });
          break;
      }
      return schema;
    },
    [validateTextCountryProhibitedKeywords],
  );

  const isAnyDangerousItemsInContainer = (container: any) => {
    return (
      customValueIsTrue(container?.customValues?.dangerousGoods) ||
      customValueIsTrue(container?.customValues?.lithiumBatteriesGoods)
    );
  };

  const getDeclarationSchema = useCallback(
    (t: any, portalName: string, config?: any) => {
      let schema = null;
      switch (portalName) {
        case "portal":
          schema = yup.array().of(
            yup.object().shape({
              isGovernmentChecked: yup.boolean().when(["container"], {
                is: isAnyDangerousItemsInContainer,
                then: yup.boolean(),
                otherwise: yup
                  .boolean()
                  .required(t("validation.declaration.agreeWithExportRules"))
                  .oneOf(
                    [true],
                    t("validation.declaration.agreeWithExportRules"),
                  ),
              }),
              container: yup.object().shape({
                customValues: yup.object().shape({
                  purposeOfCommodity: yup
                    .string()
                    .required(t("validation.declaration.descriptionRequired"))
                    .oneOf(
                      $enum(PurposeOfCommodityValues).getKeys(),
                      t("validation.declaration.descriptionRequired"),
                    ),
                }),
                weight: yup
                  .string()
                  .matches(onlyNumeric, t("validation.code.digits"))
                  .nullable(),
              }),
              commodities: yup
                .array()
                .of(getCommodityDeclarationSchema(t, portalName))
                .min(1),
            }),
          );
          break;

        case "parcels":
          schema = yup.array().of(
            yup.object().shape({
              isGovernmentChecked: yup.boolean().when(["container"], {
                is: isAnyDangerousItemsInContainer,
                then: yup.boolean(),
                otherwise: yup
                  .boolean()
                  .required(t("validation.declaration.agreeWithExportRules"))
                  .oneOf(
                    [true],
                    t("validation.declaration.agreeWithExportRules"),
                  ),
              }),
              container: yup
                .object()
                .test(
                  "parcelValidation",
                  (params) => handleParcelValidationMessage(params, config),
                  function () {
                    return validateParcel(this.parent.container, config);
                  },
                )
                .shape({
                  customValues: yup.object().shape({
                    purposeOfCommodity: yup
                      .string()
                      .required(t("validation.declaration.descriptionRequired"))
                      .oneOf(
                        $enum(PurposeOfCommodityValues).getKeys(),
                        t("validation.declaration.descriptionRequired"),
                      ),
                  }),
                  weight: yup
                    .string()
                    .required(t("validation.declaration.weightRequired"))
                    .matches(onlyNumeric, t("validation.code.digits"))
                    .test(
                      "weight-above-zero",
                      t("validation.declaration.weightGreaterZero"),
                      testNumberGreaterThanZero,
                    )
                    .nullable(),
                  length: yup
                    .string()
                    .required(t("validation.declaration.lengthRequired"))
                    .matches(onlyNumeric, t("validation.code.digits"))
                    .test(
                      "length-above-zero",
                      t("validation.declaration.lengthGreaterZero"),
                      testNumberGreaterThanZero,
                    )
                    .nullable(),
                  width: yup
                    .string()
                    .required(t("validation.declaration.widthRequired"))
                    .matches(onlyNumeric, t("validation.code.digits"))
                    .test(
                      "width-above-zero",
                      t("validation.declaration.widthGreaterZero"),
                      testNumberGreaterThanZero,
                    )
                    .nullable(),
                  height: yup
                    .string()
                    .required(t("validation.declaration.heightRequired"))
                    .matches(onlyNumeric, t("validation.code.digits"))
                    .test(
                      "height-above-zero",
                      t("validation.declaration.heightGreaterZero"),
                      testNumberGreaterThanZero,
                    )
                    .nullable(),
                }),
              commodities: yup
                .array()
                .of(getCommodityDeclarationSchema(t, portalName))
                .min(1),
            }),
          );
          break;
      }
      return schema;
    },
    [
      testNumberGreaterThanZero,
      handleParcelValidationMessage,
      validateParcel,
      getCommodityDeclarationSchema,
    ],
  );

  return {getDeclarationSchema};
};
