import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Button, Skeleton, Typography} from "@mui/material";
import {AddBox} from "@mui/icons-material";
import {Trans, useTranslation} from "react-i18next";
import {ContactPaymentMethodDto} from "../../../features/paymentMethod/paymentMethod-api";
import PaymentMethodDetails from "./paymentMethodDetails";
import NewCardDetails from "../../../Delivery/PaymentMethod/components/newCardDetails";
import {useAppSelector} from "../../../store";
import {useGetPaymentMethods} from "../hooks/useGetPaymentMethods";
import {toastError} from "../../../common/utils/toastMessages";
import {AutoPayment} from "../../../common/components/autoPayment";
import Link from "../../../common/components/link";
import {VerificationState} from "../../../features/verification/verification-slice";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../../features/workflowTriggers/workflowExecution-api";
import useGetUserContact from "../../../common/hooks/useGetUserContact";
import {
  customValueIsTrue,
  getCustomValue,
} from "../../../../utils/helper.utils";

const SkeletonCard = () => (
  <Grid xs={3} md={2} lg={2}>
    <Skeleton
      variant="rectangular"
      sx={{
        width: "100%",
        height: "137px",
        borderRadius: 2,
      }}
    />
  </Grid>
);

export default function AccountPaymentMethodsForm() {
  const {t} = useTranslation();

  const [selectedValue, setSelectedValue] = useState("");
  const [cardData, setCardData] =
    useState<ContactPaymentMethodDto | null>(null);
  const [addingNewCard, setAddingNewCard] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState<
    ContactPaymentMethodDto[] | []
  >([]);
  const [isAutoPay, setIsAutoPay] = useState<boolean>(false);
  const [isInitialRender, setInitialRender] = useState(true);

  const userState = useAppSelector((state: any) => state.userState);
  const verificationState = useAppSelector(
    (state: any) => state.verificationState as VerificationState,
  );

  const {reloadContact, isLoading: isContactLoading} = useGetUserContact();

  const [runWorkflow, {isLoading: isRunWorkflowLoading}] =
    useWorkflowExecutionMutation();

  const changePaymentInfoWorkflowId = useAppSelector(
    (state: any) =>
      state.organizationConfigState?.workflows?.changePaymentInfoWorkflowId,
  );

  const updateContactInfo = async (paymentId: string) => {
    const workflowData: {
      contactId: number;
      autoPay: string;
      defaultPaymentId: string | undefined;
    } = {
      contactId: userState.contactId,
      autoPay: isAutoPay.toString(),
      defaultPaymentId: paymentId,
    };

    const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
      organizationId: process.env
        .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
      workflowId: changePaymentInfoWorkflowId,
      values: {variables: {...workflowData}},
    };

    return runWorkflow(executeWorkflowApiArgs);
  };

  const {fetchPaymentMethods, isLoading} = useGetPaymentMethods();

  const handleChange = async (value: any, closeModal: any) => {
    try {
      await updateContactInfo(value);
      setSelectedValue(value);
    } catch (error) {
      toastError(error);
    } finally {
      closeModal();
    }
  };

  const handleChangeAutoPayment = async () => {
    try {
      updateContactInfo(selectedValue);
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    if (!isInitialRender) {
      handleChangeAutoPayment();
    } else {
      setInitialRender(false);
    }
  }, [isAutoPay]);

  const handleAddingCard = () => {
    setCardData(null);
    setAddingNewCard(true);
  };

  useEffect(() => {
    fetchPaymentMethods().then((result: any) => {
      setPaymentMethods(result?.data?.items);
    });

    reloadContact().then((contact: any) => {
      if (contact?.customValues) {
        const defaultPaymentId = getCustomValue(
          contact?.customValues,
          "default_payment_id",
        );
        if (defaultPaymentId) setSelectedValue(defaultPaymentId);
        setIsAutoPay(customValueIsTrue(contact?.customValues?.autoPay));
      }
    });
  }, [addingNewCard]);

  if (addingNewCard) {
    return (
      <Grid container columns={{xs: 6, md: 12, lg: 12}}>
        <Grid xs={6} md={6} lg={6} mt={{xs: 5, md: 0, lg: 0}}>
          <NewCardDetails
            handleVisibilityClick={setAddingNewCard}
            cardData={cardData}
          />
        </Grid>
      </Grid>
    );
  }

  return isContactLoading ? (
    <Grid container columns={{xs: 6, md: 12, lg: 12}} direction="column">
      <Grid xs={6} md={2} mt={{xs: 3, md: 0}} pr={{xs: 0, md: 2}}>
        <Skeleton
          variant="rectangular"
          sx={{
            mb: 3,
            borderRadius: 1,
            width: "100%",
            height: "56px",
          }}
        />
      </Grid>
      <Grid xs={6} md={12}>
        <Grid container columns={{xs: 6, md: 12, lg: 12}}>
          <Grid xs={6} md={8} lg={8}>
            <Grid
              container
              columns={{xs: 6, md: 8, lg: 8}}
              spacing={{xs: 1, md: 3}}
              direction="row"
            >
              {isLoading ? (
                <SkeletonCard />
              ) : (
                paymentMethods.map((method) => (
                  <SkeletonCard key={method.contactPaymentMethodId} />
                ))
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container columns={{xs: 6, md: 12, lg: 12}} direction="column">
      <Grid
        mt={1}
        display={verificationState.contactAddress.verified ? "none" : "block"}
      >
        <Typography
          color={"error.main"}
          sx={{textAlign: {xs: "center", md: "left"}}}
        >
          <Trans i18nKey={"delivery.paymentMethod.verificationNeeded"} />{" "}
          <Link to="../verifications">
            <Trans i18nKey={"delivery.paymentMethod.completeVerification"} />
          </Link>
        </Typography>
      </Grid>
      <Grid xs={6} md={4} lg={3} mt={1}>
        <Button
          fullWidth
          disabled={!verificationState.contactAddress.verified}
          color="primary"
          size="medium"
          sx={{borderRadius: "8px", minHeight: "56px"}}
          variant="outlined"
          startIcon={<AddBox style={{fontSize: "30px"}} color="primary" />}
          onClick={handleAddingCard}
          data-testid="add-new-card-button"
        >
          <Typography
            display={"flex"}
            color="black"
            textTransform="none"
            data-testid="add-new-card-text"
          >
            <Trans i18nKey="addNewCard">Add a new card</Trans>
          </Typography>
        </Button>
      </Grid>
      <Grid xs={6} md={8} lg={8} mt={3}>
        {paymentMethods.length > 0 ? (
          <Grid
            container
            columns={{xs: 6, md: 8, lg: 8}}
            spacing={{xs: 1, md: 3}}
            direction="row"
          >
            {paymentMethods.map((method) => {
              if (method.description) {
                const [paymentType, , cardNumberValue] =
                  method.description.split(" ");
                return (
                  <Grid
                    xs={3}
                    md={2}
                    lg={2}
                    key={method.contactPaymentMethodId}
                    data-testid={`payment-method-${method.contactPaymentMethodId}`}
                  >
                    <PaymentMethodDetails
                      defaultLoading={isRunWorkflowLoading}
                      cardDataValues={method}
                      setCardData={setCardData}
                      setAddingNewCard={setAddingNewCard}
                      cardType={paymentType || "unknown"}
                      cardNumber={cardNumberValue || "N/A"}
                      selectedValue={selectedValue}
                      handleChange={handleChange}
                      setPaymentMethods={setPaymentMethods}
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
        ) : (
          <Grid sx={{display: "flex", justifyContent: "start"}}>
            <Typography variant="body4">
              {t("profile.paymentMethods.noPaymentsYet")}
            </Typography>
          </Grid>
        )}
      </Grid>
      <AutoPayment
        mt={3}
        maxWidth={"650px"}
        isAutoPay={isAutoPay}
        disabled={!(paymentMethods?.length > 0)}
        handleChangeAutoPayment={() => setIsAutoPay(!isAutoPay)}
      />
    </Grid>
  );
}
