import {useParams} from "react-router-dom";
import {useCallback, useEffect, useMemo, useState} from "react";
import {RootState, useAppSelector} from "../../store";
import {
  DraftInfo,
  OrderStepData,
  setCurrentDraftInfo,
} from "../../features/order/parcelShipment-slice";
import {compareArrays} from "../../../utils/compareArrays.utils";
import {
  useWorkflowExecutionMutation,
  WorkflowExecutionApiArg,
} from "../../features/workflowTriggers/workflowExecution-api";
import {useDispatch} from "react-redux";
import {
  ChargeGqlDto,
  OrderGQLDto,
  OrderTypes,
  RateDto,
} from "../../features/order/order-api";
import {customValueIsTrue, getCustomValue} from "../../../utils/helper.utils";
import {ContactPaymentMethodDto} from "../../features/paymentMethod/paymentMethod-api";
import useGetUserContact from "../../common/hooks/useGetUserContact";

type UrlParams = {
  orderIds: string;
  step: string;
};

export const useDeliveryHelper = () => {
  const {orderIds: orderIdsParam, step: stepKey} = useParams<
    keyof UrlParams
  >() as UrlParams;

  const dispatch = useDispatch();

  const {getContact} = useGetUserContact();

  const orderIds = useMemo(() => {
    return orderIdsParam?.split("&")?.map((x) => Number(x));
  }, [orderIdsParam]);

  const isConsolidated = useMemo(() => {
    return orderIds?.length > 1 || orderIds?.includes(0);
  }, [orderIds]);

  const ordersData = useAppSelector(
    (state: any) => state.parcelShipmentState.ordersData,
  );
  const currentOrderData = useMemo(() => {
    return ordersData.find((x: OrderStepData) =>
      compareArrays(x?.orderIds, orderIds),
    );
  }, [ordersData, orderIds, compareArrays]);

  const [draftInfo, setDraftInfo] = useState<DraftInfo | null>(null);

  useEffect(() => {
    if (currentOrderData?.currentDraftInfo) {
      setDraftInfo(currentOrderData.currentDraftInfo);
    }
  }, [currentOrderData?.currentDraftInfo]);

  const createDraftParcelWorkflowId = useAppSelector(
    (state: RootState) =>
      state.organizationConfigState?.workflows?.createDraftParcelWorkflowId,
  );
  const getDraftParcelWorkflowId = useAppSelector(
    (state: RootState) =>
      state.organizationConfigState?.workflows?.getDraftParcelWorkflowId,
  );
  const deleteDraftWorkflowId = useAppSelector(
    (state: RootState) =>
      state.organizationConfigState?.workflows?.deleteDraftWorkflowId,
  );
  const customerId = useAppSelector((state) => state?.userState?.contactId);
  const [runWorkflow] = useWorkflowExecutionMutation();

  let getDraftPromise: any = null;

  type DraftResult = {
    draftParcelShipment: OrderGQLDto;
    purchases: OrderGQLDto[];
    purchase: OrderGQLDto;
    contactPaymentMethod: ContactPaymentMethodDto;
    rate: RateDto;
  };
  const mapDraftToState = async (
    draftResult: DraftResult,
  ): Promise<OrderStepData> => {
    const draft = draftResult?.draftParcelShipment;
    const consignee = draft?.consignee?.contact;
    const consigneeAddress = draft?.consignee?.contactAddress;
    const commodities = [{...draft?.orderCommodities?.[0]?.commodity}];
    const customerContact = await getContact();
    const customerAutoPay = customerContact?.customValues?.autoPay
      ? customValueIsTrue(customerContact?.customValues?.autoPay)
      : false;
    const customerDefaultPaymentMethod = {
      contactPaymentMethodId: getCustomValue(
        customerContact?.customValues,
        "default_payment_id",
      ),
    };

    const rate = draftResult?.rate;

    const orderStepData = {} as OrderStepData;

    orderStepData.loadedDraft = draftResult;

    orderStepData.orderIds =
      (draftResult?.purchases
        ?.map((x) => x.orderId)
        ?.filter((x) => !!x) as number[]) ?? [];

    orderStepData.isConsolidated = orderStepData?.orderIds?.length > 1;

    orderStepData.order = {
      orderType: (draft?.orderType as OrderTypes) ?? OrderTypes.parcelShipment,
      billToContactId: (draft?.billToContactId as number) ?? customerId,
      consigneeValues: {
        contactId: consignee?.contactId,
        emailAddress: consignee?.emailAddress,
        firstName: consignee?.contactFirstName,
        lastName: consignee?.contactLastName,
        phoneNumber: consignee?.phoneNumber,
      },
      consigneeAddressValues: {
        contactAddressId: consigneeAddress?.contactAddressId,
        countryCode: consigneeAddress?.countryCode,
        countryName: consigneeAddress?.country?.name,
        regionCode: consigneeAddress?.stateCode,
        regionName: consigneeAddress?.state?.name,
        district: getCustomValue(
          consigneeAddress?.customValues,
          "toAddressDistrict",
        ),
        city: consigneeAddress?.cityName,
        postalCode: consigneeAddress?.postalCode,
        streetName: consigneeAddress?.addressLine,
        houseNumber: getCustomValue(
          consigneeAddress?.customValues,
          "toAddressHouseNumber",
        ),
        apartment: getCustomValue(
          consigneeAddress?.customValues,
          "toAddressApartment",
        ),
      },
      isPickupLocation:
        getCustomValue(consigneeAddress?.customValues, "is_pickup_location") ===
        "true",
      charges: draft?.charges?.map((x: ChargeGqlDto) => {
        return {
          organizationId: x?.organizationId,
          values: {...x, chargeId: 0},
        };
      }),
      contactPaymentMethod: {
        ...(draftResult?.contactPaymentMethod ?? customerDefaultPaymentMethod),
      },
      customValues: {
        ...draft?.customValuesMap,
        autoPay: draft?.customValuesMap?.autoPay
          ? customValueIsTrue(draft?.customValuesMap?.autoPay)
          : customerAutoPay,
        isConsolidated: customValueIsTrue(
          draft?.customValuesMap?.isConsolidated,
        ),
      },
      deliveryMethod: {
        accountingItemId: rate?.accountingItemId,
        currencyId: rate?.currencyId,
        daysFrom: rate?.transitDaysMin,
        daysTo: rate?.transitDaysMax,
        deliveryType: rate?.customValues,
        estimatedCost: draft?.customValuesMap?.estimatedShippingCost,
        rateId: Number(rate?.rateId),
        totalAmount: null,
        modeOfTransportation: {
          modeOfTransportationId:
            rate?.modeOfTransportation?.modeOfTransportationId,
          description: rate?.modeOfTransportation?.description,
        },
      },
      containerCommodities: commodities,
    };
    try {
      orderStepData.completedSteps = JSON.parse(
        draft?.customValuesMap?.draftCompletedSteps ?? "",
      );
    } catch (parseError) {
      console.log(parseError);
    }
    orderStepData.isNewDraft = true;

    return orderStepData;
  };

  const getDraft = useCallback(
    async ({orderIds}: {orderIds: number[]}) => {
      if (!orderIds || !customerId) return;
      const data: any = {
        purchaseOrderIds: orderIds,
        customerId: customerId,
      };

      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: getDraftParcelWorkflowId,
        values: {variables: {...data}},
      };

      if (!getDraftPromise) {
        getDraftPromise = runWorkflow(executeWorkflowApiArgs);
      }

      try {
        const getDraftResult = await getDraftPromise;
        const outputs = getDraftResult?.data?.outputs;
        if (outputs?.draftParcelShipment) {
          return await mapDraftToState(outputs);
        }
      } catch (error) {
        console.log(error);
      } finally {
        getDraftPromise = null;
      }
      return null;
    },
    [getDraftParcelWorkflowId, runWorkflow, customerId, orderIds],
  );

  const mapToDraftInfo = useCallback((data: any): DraftInfo => {
    return {
      purchaseTrackingNumber: data?.purchase?.trackingNumber,
      draftOrderNumber: data?.draftParcelShipment?.orderNumber,
    };
  }, []);

  let saveDraftPromise: any = null;
  const saveDraft = useCallback(
    async (
      currentOrderData: OrderStepData | null,
      isSubmit: boolean | null = false,
    ) => {
      if (
        !currentOrderData?.isNewDraft ||
        currentOrderData?.orderIds?.includes(0)
      )
        return;

      const data: any = {
        purchaseOrderIds: currentOrderData.orderIds,
        customerId: customerId,
        consigneeContactId: currentOrderData?.order?.consigneeValues?.contactId,
        consigneeContactAddressId:
          currentOrderData?.order?.consigneeAddressValues?.contactAddressId,
        charges: currentOrderData?.order?.charges?.map((x: any) => ({
          organizationId: x?.organizationId,
          values: {
            ...x?.values,
            rate: undefined,
            accountingItem: undefined,
            accountingTransactions: undefined,
            orders: undefined,
            orderChargeLinks: undefined,
            links: undefined,
          },
        })),
        customValues: {
          ...currentOrderData?.order?.customValuesMap,
          autoPay: currentOrderData?.order?.customValues?.autoPay,
          selectedDeliveryMethodId:
            currentOrderData?.order?.deliveryMethod?.rateId,
          contactPaymentMethod:
            currentOrderData?.order?.contactPaymentMethod
              ?.contactPaymentMethodId,
          contactPaymentMethodDescription:
            currentOrderData?.order?.contactPaymentMethod?.description,
          draftCompletedSteps: JSON.stringify(currentOrderData?.completedSteps),
          isConsolidated: isConsolidated,
          estimatedShippingCost:
            currentOrderData?.order?.deliveryMethod?.estimatedCost,
          customer_notes: currentOrderData?.order?.customValues?.customer_notes,
          promoCode: currentOrderData?.order?.customValues?.promoCode,
        },
        isDraftSubmit: isSubmit,
      };

      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: createDraftParcelWorkflowId,
        values: {variables: {...data}},
      };

      if (!saveDraftPromise) {
        saveDraftPromise = runWorkflow(executeWorkflowApiArgs);
      }

      try {
        const workflowResult = await saveDraftPromise;
        const outputs = workflowResult?.data?.outputs;
        if (outputs) {
          dispatch(
            setCurrentDraftInfo({orderIds, draftInfo: mapToDraftInfo(outputs)}),
          );
        }
        return workflowResult;
      } catch (error) {
        console.log(error);
      } finally {
        saveDraftPromise = null;
      }
    },
    [createDraftParcelWorkflowId, runWorkflow, customerId, currentOrderData],
  );

  let deleteDraftPromise: any = null;
  const deleteDraft = useCallback(
    async (orderIds: number[]) => {
      const data: any = {
        purchaseOrderIds: orderIds,
        customerId: customerId,
      };

      const executeWorkflowApiArgs: WorkflowExecutionApiArg = {
        organizationId: process.env
          .REACT_APP_PORTAL_ORGANIZATION_ID as unknown as number,
        workflowId: deleteDraftWorkflowId,
        values: {variables: {...data}},
      };

      if (!deleteDraftPromise) {
        deleteDraftPromise = runWorkflow(executeWorkflowApiArgs);
      }

      try {
        return await deleteDraftPromise;
      } catch (error) {
        console.log(error);
      } finally {
        deleteDraftPromise = null;
      }
    },
    [currentOrderData, customerId, deleteDraftWorkflowId, runWorkflow],
  );

  return {
    stepKey,
    orderIdsParam,
    orderIds,
    isConsolidated,
    ordersData,
    currentOrderData,
    currentOrder: currentOrderData?.order,
    completedSteps: currentOrderData?.completedSteps,
    getDraft,
    saveDraft,
    deleteDraft,
    draftInfo,
  };
};
