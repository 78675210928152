import React from "react";
import {Tooltip as MuiTooltip, TooltipProps} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import HelpIcon from "@mui/icons-material/Help";

export default function Tooltip({
  children,
  title = "Tooltip Text",
}: TooltipProps) {
  return (
    <MuiTooltip title={title} arrow={true} sx={{cursor: "default"}}>
      <Grid display={"flex"} gap={1} alignItems={"center"}>
        {children}
        <HelpIcon fontSize={"small"} color={"primary"} />
      </Grid>
    </MuiTooltip>
  );
}
