import {toastError} from "../../common/utils/toastMessages";
import {basePortalApi as api} from "../basePortalApi";
import {t} from "i18next";
import uuid from "react-uuid";
import {deleteOrder} from "./parcelShipment-slice";
import {
  AccountingItemDto,
  ItemType,
} from "../accountingItem/accountingItems-api";
import {
  AccountingTransactionDto,
  DocumentTemplateType,
} from "../accountingTransaction/accountingTransaction-api";
import {AttachmentDto} from "../attachment/attachment-api";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    orderCreateOrder: build.mutation<
      OrderCreateOrderApiResponse,
      OrderCreateOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/divisions/${queryArg.divisionId}/orders/createOrder`,
        method: "POST",
        body: queryArg.createOrderPortalCommand,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          if (
            (error.error?.status == 400 &&
              error.error?.data?.detail?.includes("with tracking number") &&
              error.error?.data?.detail?.includes("already exists")) ||
            (error.error?.status == 500 &&
              error.error?.data?.title?.includes("Tracking Number"))
          ) {
            toastError(t("toasts.purchaseWithTrackingNumberIsAlreadyExists"));
          } else if (
            error.error?.status == 400 &&
            error.error?.data?.detail?.includes("Description is required.")
          ) {
            toastError(t("validation.editPurchases.descriptionRequired"));
          } else toastError(error);
        }
      },
    }),
    orderGetOrders: build.mutation<
      OrderGetOrdersApiResponse,
      OrderGetOrdersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/divisions/${queryArg.divisionId}/orders/getOrders`,
        method: "POST",
        body: queryArg.getOrdersCommandValues,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    orderGetOrdersGQL: build.mutation<
      OrderGetOrdersApiResponse,
      OrderGetOrdersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/divisions/${queryArg.divisionId}/orders/gql/getOrders`,
        method: "POST",
        body: queryArg.getOrdersCommandValues,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    orderGetOrder: build.mutation<
      OrderGetOrderApiResponse,
      OrderGetOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/divisions/${queryArg.divisionId}/orders/${queryArg.orderId}`,
        method: "GET",
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    orderPatchOrder: build.mutation<
      OrderPatchOrderApiResponse,
      OrderPatchOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/divisions/${queryArg.divisionId}/orders/${queryArg.orderId}`,
        method: "PATCH",
        body: queryArg.patchOrderCommandValues,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    orderDeleteOrder: build.mutation<
      OrderDeleteOrderApiResponse,
      OrderDeleteOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/divisions/${queryArg.divisionId}/orders/${queryArg.orderId}`,
        method: "DELETE",
      }),
      async onQueryStarted(args, {dispatch, queryFulfilled}) {
        try {
          await queryFulfilled;
          dispatch(deleteOrder({orderIds: [args.orderId]}));
        } catch (error: any) {
          toastError(error);
        }
      },
    }),
    orderPutOrder: build.mutation<
      OrderPutOrderApiResponse,
      OrderPutOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/organizations/${queryArg.organizationId}/divisions/${queryArg.divisionId}/orders/${queryArg.orderId}`,
        method: "PUT",
        body: queryArg.updateOrderPortalCommand,
      }),
      async onQueryStarted(args, {queryFulfilled}) {
        try {
          await queryFulfilled;
        } catch (error: any) {
          if (
            error.error?.status == 400 &&
            error.error?.data?.detail?.includes(
              "'Description' не должно быть пусто.",
            )
          ) {
            toastError(t("validation.editPurchases.descriptionRequired"));
          } else toastError(error);
        }
      },
    }),
  }),
  overrideExisting: false,
});
export {injectedRtkApi as portalApi};
export type OrderCreateOrderApiResponse = OrderDto;
export type OrderCreateOrderApiArg = {
  organizationId: number;
  divisionId: number;
  createOrderPortalCommand: CreateOrderPortalCommand;
};
export type OrderGetOrdersApiResponse = OrderDtoPagedResult;
export type OrderGetOrdersApiArg = {
  organizationId: number;
  divisionId: number;
  getOrdersCommandValues: GetOrdersCommandValues;
};
export type OrderGetOrderApiResponse = OrderDto;
export type OrderGetOrderApiArg = {
  organizationId: number;
  divisionId: number;
  orderId: number;
};
export type OrderPatchOrderApiResponse = OrderDto;
export type OrderPatchOrderApiArg = {
  organizationId: number;
  orderId: number;
  divisionId: number;
  patchOrderCommandValues: PatchOrderCommandValues;
};
export type OrderDeleteOrderApiResponse = unknown;
export type OrderDeleteOrderApiArg = {
  organizationId: number;
  orderId: number;
  divisionId: number;
};
export type OrderPutOrderApiResponse = /** status 200  */ undefined;
export type OrderPutOrderApiArg = {
  organizationId: number;
  orderId: number;
  divisionId: number;
  updateOrderPortalCommand: UpdateOrderPortalCommand;
};
export type AddressType = "Billing" | "Shipping" | "Other";
export type LinkDto = {
  href?: string | null;
  rel?: string | null;
  type?: string | null;
  templated?: boolean | null;
};
export type ContactAddressDto = {
  contactAddressId?: number;
  addressLine?: string | null;
  addressLine2?: string | null;
  addressType?: AddressType;
  cityName?: string | null;
  contactId?: number;
  countryCode?: string | null;
  countryName?: string | null;
  created?: string;
  createdBy?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  organizationId?: number;
  postalCode?: string | null;
  stateCode?: string | null;
  stateName?: string | null;
  links?: LinkDto[] | null;
  longitude?: number;
  latitude?: number;
  customValues?: {
    [key: string]: string;
  } | null;
};

export type ContactAddressGqlDto = {
  contactAddressId?: number;
  addressLine?: string | null;
  addressLine2?: string | null;
  addressType?: AddressType;
  cityName?: string | null;
  contactId?: number;
  country?: CountryGqlDto | null;
  state?: StateGqlDto | null;
  city?: CityGqlDto | null;
  countryCode?: string | null;
  countryName?: string | null;
  created?: string;
  createdBy?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  organizationId?: number;
  postalCode?: string | null;
  stateCode?: string | null;
  stateName?: string | null;
  links?: LinkDto[] | null;
  longitude?: number;
  latitude?: number;
  customValues?: ICustomValuesGQL[] | null;
  formattedAddress?: string | null;
};

export type StateGqlDto = {
  countryCode?: string | null;
  name?: string | null;
  stateCode?: string | null;
  organizationId?: number;
  links?: LinkDto[] | null;
  customValues?: ICustomValuesGQL[] | null;
};

export type CityGqlDto = {
  organizationId?: number;
  cityId?: number;
  cityName: string;
  countryCode: string;
  countryName?: string | undefined;
  stateCode?: string | undefined;
  stateName?: string | undefined;
  state: StateGqlDto;
  latitude?: number | undefined;
  longitude?: number | undefined;
  customValues?: ICustomValuesGQL[] | null;
  links?: LinkDto[] | null;
};

export type PaymentTermDto = {
  paymentTermId?: number;
  description?: string | null;
  discountPaidWithinDays?: number | null;
  discountPercentage?: number | null;
  isInactive?: boolean;
  netDueDays?: number;
  organizationId?: number;
  links?: LinkDto[] | null;
};
export type ContactType =
  | "Customer"
  | "Carrier"
  | "Vendor"
  | "Contact"
  | "Driver"
  | "Employee"
  | "SalesPerson"
  | "ForwardingAgent"
  | "FactoringCompany"
  | "Lead";
export type IdNumberType = "EIN" | "DUNS" | "ForeignEntityId" | "Other";
export type VisibleTransactions =
  | "AllTransactions"
  | "DivisionOnly"
  | "DivisionAndDivisionless";
export type ApplicationUserRoleDto = {
  id?: string | null;
  name?: string | null;
  normalizedName?: string | null;
};
export type UserGroupDto = {
  userGroupId?: number;
  created?: string;
  createdBy?: string | null;
  description?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  name?: string | null;
  organizationId?: number;
  userRoles?: ApplicationUserRoleDto[] | null;
  links?: LinkDto[] | null;
};
export type UserEmployeeDto = {
  userEmployeeId?: number;
  created?: string;
  createdBy?: string | null;
  employeeContactId?: number;
  lastModified?: string;
  lastModifiedBy?: string | null;
  organizationId?: number;
  userId?: string | null;
  visibleTransactions?: VisibleTransactions;
  employeeContactFirstName?: string | null;
  employeeContactLastName?: string | null;
  employeeName?: string | null;
  userFirstName?: string | null;
  userLastName?: string | null;
  userNormalizedUserName?: string | null;
  userUserName?: string | null;
  employeeDivisionId?: number;
  userGroups?: UserGroupDto[] | null;
  links?: LinkDto[] | null;
};
export type PaidAs = "Prepaid" | "Collect";
export type CarrierEquipmentDto = {
  carrierId?: number;
  equipmentTypeId?: number;
  quantity?: number;
};
export type CreateContactDto = {
  contactAddresses?: ContactAddressDto[] | null;
  paymentTerm?: PaymentTermDto | null;
  contactId?: number;
  accountNumber?: string | null;
  contactFirstName?: string | null;
  contactLastName?: string | null;
  contactType?: ContactType;
  created?: string;
  createdBy?: string | null;
  createdByUserName?: string | null;
  divisionId?: number;
  divisionName?: string | null;
  emailAddress?: string | null;
  faxNumber?: string | null;
  idNumber?: string | null;
  idNumberType?: IdNumberType;
  lastModified?: string;
  lastModifiedBy?: string | null;
  lastModifiedByUserName?: string | null;
  mobilePhoneNumber?: string | null;
  name?: string | null;
  organizationId?: number;
  parentContactId?: number | null;
  phoneNumber?: string | null;
  website?: string | null;
  paymentTermId?: number | null;
  paymentTermDescription?: string | null;
  userEmployee?: UserEmployeeDto | null;
  creditLimit?: number | null;
  paidAs?: PaidAs | null;
  isACorporation?: boolean | null;
  carrierEquipments?: CarrierEquipmentDto[] | null;
  customValues?: {
    [key: string]: string;
  } | null;
  links?: LinkDto[] | null;
};
export type ValidationProblemDetailsResponse = {
  errors?: {
    [key: string]: string[];
  } | null;
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  additionalProperties?: {
    [key: string]: any;
  } | null;
  [key: string]: any | null;
};

export enum OrderTypes {
  order = 0,
  quote = 1,
  warehouseReceipt = 2,
  purchase = 3,
  parcelShipment = 4,
  airShipmentOrder = 5,
}

export type CreateConsigneeCommandValues = {
  contactId?: number | null;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  emailAddress?: string | null;
  customValues?: any;
};
export type CreateConsigneeAddressCommandValues = {
  contactAddressId?: number | null;
  countryCode?: string | null;
  countryName?: string | null;
  regionCode?: string | null;
  regionName?: string | null;
  district?: string | null;
  city?: string | null;
  postalCode?: string | null;
  streetName?: string | null;
  houseNumber?: string | null;
  apartment?: string | null;
  latitude?: number | null;
  longitude?: number | null;
};
export type CreateCommodityPortalCommand = {
  uuid?: any;
  commodityId?: number;
  description?: string | null;
  note?: string | null;
  quantity?: number | null;
  pieces?: number;
  unit?: string | null;
  unitaryValue?: number | null;
  weight?: number | null;
  customValues?: CommodityCustomValues | null;
  commodityType?: CommodityTypeDto | null;
  commodityTypeId?: number | null;
  weightUnit?: string | null;
  dimensionsUnit?: DimensionsUnit | undefined;
  containerCommodities?: CreateCommodityPortalCommand[] | null;
  height?: number | null;
  length?: number | null;
  width?: number | null;
  warehouseLocationId?: number | null;
  volumeUnit?: VolumeUnit;
};

export interface CommodityCustomValues {
  purposeOfCommodity?: PurposeOfCommodityValues;
  dangerousGoods?: boolean;
  lithiumBatteriesGoods?: boolean;
  customer_declared_weight?: number | null;
  customer_declared_weight_unit?: WeightUnit | null;
}

export type ApplyBy =
  | "Pieces"
  | "Weight"
  | "Volume"
  | "Container"
  | "Calculated";
export type ChargeType = "Income" | "Expense";
export type ChargeStatus = "Pending" | "Open" | "Posted" | "Paid" | "Void";
export type CreateChargeCommandValues = {
  organizationId?: number;
  accountingItemId?: number | null;
  accountingItemCode?: string | null;
  accountingItem?: AccountingItemDto;
  accountingItemName?: string | null;
  currencyId?: number | null;
  amount?: number;
  applyBy?: ApplyBy;
  applyToContactId?: number;
  chargeType?: ChargeType;
  chargeStatus?: ChargeStatus;
  description?: string | null;
  freightServiceClassId?: number | null;
  grossVolume?: number;
  grossWeight?: number;
  isConsolidated?: boolean;
  paidAs?: PaidAs;
  pieces?: number;
  price?: number | null;
  quantity?: number;
  showInDocuments?: boolean;
  unit?: string | null;
  salesTaxId?: number | null;
  salesTaxRate?: number;
  salesTaxAmount?: number;
  totalAmount?: number;
  note?: string | null;
  rateId?: number | null;
  rate?: any;
  allowAutomaticUpdate?: boolean;
};
export type CreateChargeCommand = {
  organizationId?: number;
  values?: CreateChargeCommandValues & {customValues?: any | null};
};
export type CreateOrderEntityCommandValues = {
  orderEntityId?: number | null;
  contactAddressId?: number | null;
  contactId?: number | null;
  nonContactName?: string | null;
  orderEntitySequence?: number;
  entityType?: EntityTypes;
  customValues?: {
    [key: string]: string;
  } | null;
};
export type CreateOrderPortalCommand = {
  trackingNumber?: string | null;
  additionalTrackingNumbers?: OrderCommodityTrackingNumber[] | null;
  billToContactId?: number;
  orderType?: OrderTypes;
  consigneeValues?: CreateConsigneeCommandValues | null;
  consigneeAddressValues?: CreateConsigneeAddressCommandValues | null;
  commodities?: CreateCommodityPortalCommand[] | null;
  quantity?: number;
  charges?: CreateChargeCommand[] | null;
  chargesTotalPrice?: number;
  orderEntities?: CreateOrderEntityCommandValues[] | null;
  vendorNonContactName?: string | null;
  carrierContactName?: string | null;
  vendor?: CreateOrderEntityCommandValues | null;
  carrier?: CreateOrderEntityCommandValues | null;
  customValues?: {
    [key: string]: any;
  };
  purchaseOrderIds?: number[];
  workflowId?: string;
};

export type OrderCommodityTrackingNumber = {
  trackingNumber?: string;
  trackingNumberType?: string;
  isPrimary?: boolean;
};

export type OrderStatuses =
  | "Pending"
  | "Open"
  | "Refused"
  | "Covered"
  | "Dispatched"
  | "OnRoute"
  | "UnLoading"
  | "Loading"
  | "InYard"
  | "Invoiced"
  | "Delivered"
  | "Completed"
  | "Cancelled";
export type OrderPickupDto = {
  orderPickupId?: number;
  created?: string;
  createdBy?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  orderId?: number;
  orderPickupSequence?: number;
  pickDate?: string | null;
  shipperAddressId?: number | null;
  shipperAddressName?: string | null;
  shipperContactId?: number | null;
  shipperContactName?: string | null;
  shipperContactType?: ContactType;
  shippingNotes?: string | null;
  shipperCityName?: string | null;
  shipperStateCode?: string | null;
  links?: LinkDto[] | null;
};
export type OrderDeliveryDto = {
  orderDeliveryId?: number;
  consigneeAddressId?: number | null;
  consigneeAddressName?: string | null;
  consigneeContactId?: number | null;
  consigneeContactName?: string | null;
  consigneeContactType?: ContactType;
  created?: string;
  createdBy?: string | null;
  deliveryDate?: string | null;
  deliveryNotes?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  orderDeliverySequence?: number;
  orderId?: number;
  consigneeCityName?: string | null;
  consigneeStateCode?: string | null;
  links?: LinkDto[] | null;
};

export enum EntityTypes {
  Shipper = "Shipper",
  Consignee = "Consignee",
  Carrier = "Carrier",
  Vendor = "Vendor",
  UltimateConsignee = "UltimateConsignee",
  NotifyParty = "NotifyParty",
  Intermediate = "Intermediate",
  ForwardingAgent = "ForwardingAgent",
  DestinationAgent = "DestinationAgent",
  PickupFrom = "PickupFrom",
  DeliverTo = "DeliverTo",
  DeliveryCarrier = "DeliveryCarrier",
  ReceivedBy = "ReceivedBy",
}

export interface OrderEntityDto {
  orderEntityId?: number;
  contactAddressId?: number | undefined;
  contactAddressName?: string | undefined;
  contactId?: number | undefined;
  contactName?: string | undefined;
  contact?: ContactDto | null;
  contactType?: ContactType;
  created?: Date;
  createdBy?: string | undefined;
  date?: Date | undefined;
  nonContactName?: string | undefined;
  notes?: string | undefined;
  lastModified?: Date;
  lastModifiedBy?: string | undefined;
  orderEntitySequence?: number;
  orderId?: number;
  contactCityName?: string | undefined;
  contactStateCode?: string | undefined;
  entityType?: EntityTypes;
  customValues?: {
    [key: string]: string;
  } | null;
  links?: LinkDto[] | undefined;
}

export interface OrderEntityGQLDto {
  orderEntityId?: number;
  contactAddressId?: number | undefined;
  contactAddress?: ContactAddressGqlDto | null;
  contactAddressName?: string | undefined;
  contactId?: number | undefined;
  contactName?: string | undefined;
  contact?: ContactGQLDto | null;
  contactType?: ContactType;
  created?: Date;
  createdBy?: string | undefined;
  date?: Date | undefined;
  nonContactName?: string | undefined;
  notes?: string | undefined;
  lastModified?: Date;
  lastModifiedBy?: string | undefined;
  orderEntitySequence?: number;
  orderId?: number;
  contactCityName?: string | undefined;
  contactStateCode?: string | undefined;
  entityType?: EntityTypes;
  attachments?: AttachmentDto[] | null;
  customValues?: {
    [key: string]: string;
  } | null;
  links?: LinkDto[] | undefined;
}

export interface OrderDocumentGQLDto {
  orderDocumentId?: number;
  orderId?: number;
  orderDocumentName?: string;
  linkToDocument?: string;
  documentTemplate?: DocumentTemplateGQLDto;
}

export interface DocumentTemplateGQLDto {
  documentTemplateId?: number;
  documentTemplateType?: DocumentTemplateType;
}

export type OrderCarrierDto = {
  orderCarrierId?: number;
  orderId?: number;
  carrierId?: number;
  createdBy?: string | null;
  created?: string;
  lastModifiedBy?: string | null;
  lastModified?: string;
};
export type CommodityStatusStage = "Pending" | "InProgress" | "Completed";
export type CommodityStatusDto = {
  statusId?: number;
  created?: string;
  createdBy?: string | null;
  description?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  organizationId?: number;
  priority?: number;
  statusName?: string | null;
  statusStage?: CommodityStatusStage;
  links?: LinkDto[] | null;
};
export type DimensionsUnit = "In" | "Cm";
export type VolumeUnit = "Ft" | "Vlb" | "Vkg" | "M";
export type WeightUnit = "Lb" | "Kg";
export type CommodityDto = {
  commodityId?: number;
  commodityStatusId?: number | null;
  commodity?: CommodityDto | null;
  commodityStatus?: CommodityStatusDto | null;
  description?: string | null;
  dimensionsUnit?: DimensionsUnit;
  height?: number | null;
  length?: number | null;
  organizationId?: number;
  packageTypeId?: number | null;
  packageTypeName?: string | null;
  pieces?: number;
  volumePiece?: number | null;
  volumeTotal?: number | null;
  volumeUnit?: VolumeUnit;
  weightTotal?: number | null;
  weight?: number | null;
  weightByTotal?: boolean;
  weightUnit?: WeightUnit;
  width?: number | null;
  quantity?: number | null;
  unit?: string | null;
  unitaryValue?: number | null;
  note?: string | null;
  commodityType?: CommodityTypeDto | null;
  commodityTypeId?: number | null;
  links?: LinkDto[] | null;
  customValues?: {
    [key: string]: string;
  } | null;
  containerCommodities?: CommodityDto[] | null;
  commodityTrackingNumber?: string | null;
  trackingNumbers?: CommodityTrackingNumberDto[] | null;
  shipments?: OrderTypeDto[] | null;
  warehouseLocationId?: number | null;
};

export type CommodityTrackingNumberDto = {
  organizationId: number;
  commodityTrackingNumberId: number;
  commodityId: number;
  trackingNumber?: string;
  trackingNumberType?: string;
  syncOrderId?: number;
  isPrimary: boolean;
  links?: LinkDto[];
};

export interface OrderTypeDto {
  orderId?: number;
  trackingNumber?: string | null;
  orderType?: string | null;
  createdDate?: Date | null;
  orderStatus?: OrderStatusDto | null;
}

export interface CommodityTypeDto {
  commodityTypeId?: number;
  code?: string | undefined;
  created?: Date;
  createdBy?: string | undefined;
  description?: string | undefined;
  isActive?: boolean;
  lastModified?: Date;
  lastModifiedBy?: string | undefined;
  organizationId?: number;
  links?: LinkDto[] | undefined;
  customValues?: {
    [key: string]: string;
  } | null;
}

export type ChargeOrderDto = {
  orderNumber?: string;
  orderType?: string;
  trackingNumber: string | null;
};
export type AccountingTransactionStatus = "Open" | "Paid" | "Void";
export type ChargeAccountingTransactionDto = {
  transactionNumber?: string;
  accountingTransactionStatus: AccountingTransactionStatus;
  accountingTransactionId: number;
};
export type ChargeDto = {
  organizationId?: number;
  chargeId?: number;
  currencyId?: number;
  accountingItemId?: number;
  accountingItem?: AccountingItemDto;
  amount?: number;
  applyBy?: ApplyBy;
  applyToContactId?: number;
  chargeType?: ChargeType;
  description?: string | null;
  freightServiceClassId?: number | null;
  grossVolume?: number;
  grossWeight?: number;
  isConsolidated?: boolean;
  paidAs?: PaidAs;
  pieces?: number;
  price?: number;
  quantity?: number;
  showInDocuments?: boolean;
  unit?: string | null;
  salesTaxCode?: string | null;
  applyToContactName?: string | null;
  applyToContactType?: ContactType;
  accountingItemName?: string | null;
  accountingItemType?: ItemType;
  accountingItemCode?: string | null;
  chargeStatus?: ChargeStatus;
  salesTaxId?: number | null;
  salesTaxRate?: number;
  salesTaxAmount?: number;
  totalAmount?: number;
  note?: string | null;
  currencyName?: string | null;
  currencySymbol?: string | null;
  currencyDecimals?: number | null;
  orders?: ChargeOrderDto[] | null;
  accountingTransactions?: ChargeAccountingTransactionDto[] | null;
  links?: LinkDto[] | null;
  rateId?: number;
  rate?: RateDto | undefined;
  calculatedOf?: CalculatedOfTypes | null;
};

export type ChargeGqlDto = {
  organizationId?: number;
  chargeId?: number;
  currencyId?: number;
  accountingItemId?: number;
  accountingItem?: AccountingItemGqlDto;
  amount?: number;
  applyBy?: ApplyBy;
  applyToContactId?: number;
  chargeType?: ChargeType;
  description?: string | null;
  freightServiceClassId?: number | null;
  grossVolume?: number;
  grossWeight?: number;
  isConsolidated?: boolean;
  paidAs?: PaidAs;
  pieces?: number;
  price?: number;
  quantity?: number;
  showInDocuments?: boolean;
  unit?: string | null;
  salesTaxCode?: string | null;
  applyToContactName?: string | null;
  applyToContactType?: ContactType;
  accountingItemName?: string | null;
  accountingItemType?: ItemType;
  accountingItemCode?: string | null;
  chargeStatus?: ChargeStatus;
  salesTaxId?: number | null;
  salesTaxRate?: number;
  salesTaxAmount?: number;
  totalAmount?: number;
  note?: string | null;
  currencyName?: string | null;
  currencySymbol?: string | null;
  currencyDecimals?: number | null;
  orders?: ChargeOrderDto[] | null;
  accountingTransactions?: ChargeAccountingTransactionDto[] | null;
  links?: LinkDto[] | null;
  rateId?: number;
  rate?: RateDto | undefined;
  calculatedOf?: CalculatedOfTypes | null;
};

export type AccountingItemGqlDto = {
  organizationId?: number;
  accountingItemId?: number;
  accountId?: number;
  description?: string | null;
  isInactive?: boolean;
  itemCode?: string | null;
  itemType?: ItemType;
  price?: number | null;
  salesTaxId?: number | null;
  accountName?: string | null;
  salesTaxCode?: string | null;
  customValues?: {
    [key: string]: string;
  } | null;
  links?: LinkDto[] | null;
};

export enum CalculatedOfTypes {
  Income = "Income",
  Expense = "Expense",
  Profit = "Profit",
  IncomeFreight = "IncomeFreight",
}

export type TrackingEventDto = {
  trackingEventId?: number;
  created?: string;
  createdBy?: string | null;
  description?: string | null;
  eventDate?: string | null;
  eventDefinition?: EventDefinition;
  eventDefinitionId?: number;
  includeInTracking?: boolean;
  isInactive?: boolean;
  lastModified?: string;
  lastModifiedBy?: string | null;
  location?: string | null;
  sendEmail?: boolean;
  links?: LinkDto[] | null;
  customValues?: {
    [key: string]: string;
  } | null;
};

export type EventDefinition = {
  eventDefinitionId: number;
  organizationId: number;
  eventName?: string;
  description?: string;
  isInactive: boolean;
  includeInTracking: boolean;
  customValues?: {
    [key: string]: string;
  } | null;
};

export type ContactDto = {
  contactId?: number;
  accountNumber?: string | null;
  contactFirstName?: string | null;
  contactLastName?: string | null;
  contactType?: ContactType;
  created?: string;
  createdBy?: string | null;
  createdByUserName?: string | null;
  divisionId?: number;
  divisionName?: string | null;
  emailAddress?: string | null;
  faxNumber?: string | null;
  idNumber?: string | null;
  idNumberType?: IdNumberType;
  lastModified?: string;
  lastModifiedBy?: string | null;
  lastModifiedByUserName?: string | null;
  mobilePhoneNumber?: string | null;
  name?: string | null;
  organizationId?: number;
  parentContactId?: number | null;
  phoneNumber?: string | null;
  website?: string | null;
  paymentTermId?: number | null;
  paymentTermDescription?: string | null;
  userEmployee?: UserEmployeeDto;
  creditLimit?: number | null;
  paidAs?: PaidAs;
  isACorporation?: boolean | null;
  carrierEquipments?: CarrierEquipmentDto[] | null;
  customValues?: {
    [key: string]: string;
  } | null;
  links?: LinkDto[] | null;
};
export type ContactGQLDto = {
  contactId?: number;
  accountNumber?: string | null;
  contactFirstName?: string | null;
  contactLastName?: string | null;
  contactType?: ContactType;
  created?: string;
  createdBy?: string | null;
  createdByUserName?: string | null;
  divisionId?: number;
  divisionName?: string | null;
  emailAddress?: string | null;
  contactAddresses?: ContactAddressGqlDto[] | null;
  billingAddress?: ContactAddressGqlDto | null;
  faxNumber?: string | null;
  idNumber?: string | null;
  idNumberType?: IdNumberType;
  lastModified?: string;
  lastModifiedBy?: string | null;
  lastModifiedByUserName?: string | null;
  mobilePhoneNumber?: string | null;
  name?: string | null;
  organizationId?: number;
  parentContactId?: number | null;
  phoneNumber?: string | null;
  website?: string | null;
  paymentTermId?: number | null;
  paymentTermDescription?: string | null;
  userEmployee?: UserEmployeeDto;
  creditLimit?: number | null;
  paidAs?: PaidAs;
  isACorporation?: boolean | null;
  carrierEquipments?: CarrierEquipmentDto[] | null;
  customValues?: ICustomValuesGQL[] | null;
  links?: LinkDto[] | null;
};
export type OrderDto = {
  orderId?: number;
  billToContactId?: number | null;
  billToContactName?: string | null;
  billToContactType?: ContactType;
  created?: string;
  createdBy?: string | null;
  divisionId?: number;
  equipmentTypeId?: number | null;
  equipmentTypeName?: string | null;
  employeeContactId?: number | null;
  employeeContactName?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  orderNumber?: string | null;
  orderStatusId?: number;
  orderStatus?: OrderStatusDto;
  orderType?: OrderTypes | string;
  organizationId?: number;
  salespersonContactId?: number | null;
  salespersonContactName?: string | null;
  divisionName?: string | null;
  trackingNumber?: string | null;
  orderPickups?: OrderPickupDto[] | null;
  orderDeliveries?: OrderDeliveryDto[] | null;
  orderEntities?: OrderEntityDto[] | null;
  orderCarriers?: OrderCarrierDto[] | null;
  commodities?: CommodityDto[] | null;
  charges?: ChargeDto[] | null;
  trackingEvents?: TrackingEventDto[] | null;
  carriers?: ContactDto[] | null;
  createdByUserName?: string | null;
  lastModifiedByUserName?: string | null;
  shipments?: OrderDto[] | null;
  invoices?: AccountingTransactionDto[] | null;
  customValues?: {
    [key: string]: string;
  } | null;
  links?: LinkDto[] | null;
};

export interface ICustomValuesGQL {
  key: string;
  value: string;
}

export type OrderGQLDto = {
  orderId?: number;
  billToContactId?: number | null;
  billToContactName?: string | null;
  billToContactType?: ContactType;
  created?: string;
  createdBy?: string | null;
  divisionId?: number;
  equipmentTypeId?: number | null;
  equipmentTypeName?: string | null;
  employeeContactId?: number | null;
  employeeContactName?: string | null;
  lastOrderStatusModified?: string;
  lastModified?: string;
  lastModifiedBy?: string | null;
  orderNumber?: string | null;
  orderStatusId?: number;
  orderStatus?: OrderStatusGQLDto;
  orderType?: OrderTypes | string;
  orderDocuments?: OrderDocumentGQLDto[] | null;
  organizationId?: number;
  salespersonContactId?: number | null;
  salespersonContactName?: string | null;
  divisionName?: string | null;
  trackingNumber?: string | null;
  orderPickups?: OrderPickupDto[] | null;
  orderDeliveries?: OrderDeliveryDto[] | null;
  orderEntities?: OrderEntityGQLDto[] | null;
  orderCarriers?: OrderCarrierDto[] | null;
  commodities?: CommodityDto[] | null;
  charges?: ChargeGqlDto[] | null;
  trackingEvents?: TrackingEventDto[] | null;
  carriers?: ContactDto[] | null;
  createdByUserName?: string | null;
  lastModifiedByUserName?: string | null;
  orderCommodities?: OrderCommodity[] | null;
  jobs?: Job[];
  shipments?: OrderGQLDto[] | null;
  drafts?: OrderGQLDto[] | null;
  invoices?: AccountingTransactionDto[] | null;
  attachments?: AttachmentDto[] | null;
  documentAttachments?: AttachmentDto[] | null;
  customValues?: ICustomValuesGQL[] | null;
  links?: LinkDto[] | null;
  consignee?: OrderEntityGQLDto | null;
  shippingCharge?: ChargeGqlDto | null;
  customValuesMap?: any;
  modeOfTransportation?: ModeOfTransportationDto | null;
  firstMileCarrier?: OrderEntityGQLDto | null;
  shipper?: OrderEntityGQLDto | null;
};

export type OrderCommodity = {
  commodity: CommodityDto;
};

export type Job = {
  jobId: string;
  customValues?: {
    [key: string]: string;
  };
  customerId?: number;
  description?: string;
  divisionId?: number;
  employeeId: number;
  jobNumber?: string;
  jobStatusId?: number;
  organizationId: number;
  customer: ContactDto;
  employee: ContactDto;
  jobStatus: JobStatus;
  orders?: OrderGQLDto[];
  accountingTransactions?: AccountingTransactionDto[];
  createdBy?: string;
  created: string;
  lastModifiedBy?: string;
  lastModified: string;
};

export type JobStatus = {
  jobStatusId: number;
  customValues?: {
    [key: string]: string;
  };
  jobStatusName?: string;
  organizationId: number;
  priority: number;
  statusStage: StatusStage;
  createdBy?: string;
  created: string;
  lastModifiedBy?: string;
  lastModified: string;
};

export type OrderStatusDto = {
  orderStatusId: number;
  organizationId: number;
  orderStatusDescription: string;
  orderStatusName: string;
  orderType: OrderTypes;
  priority: number;
  statusStage: StatusStage;
  created?: string;
  createdBy?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  customValues?: {
    [key: string]: string;
  } | null;
  links?: LinkDto[] | null;
};

export type OrderStatusGQLDto = {
  orderStatusId: number;
  organizationId: number;
  orderStatusDescription: string;
  orderStatusName: string;
  orderType: OrderTypes;
  priority: number;
  statusStage: StatusStage;
  created?: string;
  createdBy?: string | null;
  lastModified?: string;
  lastModifiedBy?: string | null;
  customValues?: {[key: string]: string} | null;
  links?: LinkDto[] | null;
};

export enum StatusStage {
  Pending = 0,
  InProgress = 1,
  Completed = 2,
}

export enum QueryKeysEnum {
  PurchaseList = 0,
  PurchasesListWithoutJobs = 1,
  ParcelShipmentsInformation = 2,
}

export type OrderDtoPagedResult = {
  offset?: number;
  limit?: number;
  totalCount?: number;
  items?: OrderDto[] | null;
  links?: LinkDto[] | null;
};
export type OrderGQLDtoPagedResult = {
  totalCount?: number;
  items?: OrderGQLDto[] | null;
};
export type GetOrdersCommandValues = {
  limit?: number | null;
  offset?: number | null;
  sort?: string;
  filter?: string;
  search?: string;
  queryKey: QueryKeysEnum;
  lang?: string;
};
export type Operation = {
  value?: any | null;
  path?: string | null;
  op?: string | null;
  from?: string | null;
};
export type PatchOrderCommandValues = {
  operations?: Operation[] | null;
};
export type UpdateOrderEntityPortalCommand = {
  orderEntityId?: number | null;
  contactAddressId?: number | null;
  contactId?: number | null;
  orderEntitySequence?: number;
  entityType?: EntityTypes;
  nonContactName?: string | null;
  customValues?: {
    [key: string]: string;
  } | null;
};

export enum PurposeOfCommodityValues {
  PersonalMerchandise = "Personal Merchandise",
  BusinessMerchandise = "Business Merchandise",
  Gift = "Gift",
}

export enum DangerousItemsValues {
  dangerousGoods = "Potential Dangerous Goods: Perfume, Oil, Nail Polish, Aerosol Sprays, Paint (Can’t be shipped by Express Service)",
  lithiumBatteriesGoods = "Lithium batteries such as power banks, and portable electronic devices (PEDs) which contain lithium batteries such as phones, tablets, laptop computers, shavers, cameras, drones, tools, medical devices, e-bikes and scooters are also dangerous goods. In particular chargers, power banks and e-cigarettes (Must be shipped separately)",
}

export type UpdateCommodityPortalCommand = {
  commodityId?: number | null;
  uuid?: number | null;
  description?: string | null;
  note?: string | null;
  quantity?: number | null;
  pieces?: number;
  unit?: string | null;
  unitaryValue?: number | null;
  weight?: number | null;
  customValues?: CommodityCustomValues | null;
  commodityTypeId?: number | null;
  weightUnit?: string | null;
  dimensionsUnit?: string | null;
  width?: number | null;
  height?: number | null;
  length?: number | null;
  isRemoved?: boolean | null;
  volumeUnit?: VolumeUnit | null;
};
export type UpdateChargePortalCommand = {
  accountingItemId?: number | null;
  currencyId?: number | null;
  amount?: number;
  applyBy?: ApplyBy;
  applyToContactId?: number;
  chargeType?: ChargeType;
  chargeStatus?: ChargeStatus;
  description?: string | null;
  freightServiceClassId?: number | null;
  grossVolume?: number;
  grossWeight?: number;
  isConsolidated?: boolean;
  paidAs?: PaidAs;
  pieces?: number;
  price?: number | null;
  quantity?: number;
  showInDocuments?: boolean;
  unit?: string | null;
  salesTaxId?: number | null;
  salesTaxRate?: number;
  salesTaxAmount?: number;
  totalAmount?: number;
  note?: string | null;
  rateId?: number | null;
  allowAutomaticUpdate?: boolean;
};
export type UpdateOrderPortalCommand = {
  trackingNumber?: string | null;
  orderEntities?: UpdateOrderEntityPortalCommand[] | null;
  commodities?: UpdateCommodityPortalCommand[] | null;
  charges?: UpdateChargePortalCommand[] | null;
  container?: UpdateCommodityPortalCommand | null;
  customValues?: {
    [key: string]: string;
  } | null;
  workflowId?: string;
};

export enum WeightUnits {
  Lb = "Lb",
  Kg = "Kg",
}

export enum DimensionsUnits {
  Cm = "Cm",
  In = "In",
}

export type GroupedCharges = {
  name: string;
  charges: ChargeGqlDto[];
  totalPrice: number;
  totalAmount: number;
  totalQuantity: number;
  unit?: string;
  estimatedCost?: string;
  testId?: string;
};

export type RateDto = {
  rateId?: number | null;
  organizationId?: number | null;
  accountingItemId?: number | null;
  amendmendNumber?: string | null;
  applyToDestinationCountry?: boolean | null;
  applyToOriginCountry?: boolean | null;
  automaticallyCreateCharge?: boolean | null;
  finalMileCarrierId?: number | null;
  carrierId?: number | null;
  clientId?: number | null;
  commodityTypeId?: number | null;
  contractNumber?: string | null;
  countryOfDestinationCode?: string | null;
  countryOfOriginCode?: string | null;
  currencyId?: number | null;
  effectiveDate?: string | null;
  expirationDate?: string | null;
  frequency?: Frequency | null;
  isHazardous?: boolean | null;
  modeOfTransportationId?: number | null;
  notes?: string | null;
  portOfDeliveryId?: string | null;
  portOfLoadingId?: string | null;
  portOfReceiptId?: string | null;
  portOfUnloadingId?: string | null;
  rateType?: RateType | null;
  serviceType?: ServiceType | null;
  tariff?: TariffOptions | null;
  transitDaysMin?: number | null;
  transitDaysMax?: number | null;
  portOfReceiptName?: string | null;
  portOfLoadingName?: string | null;
  portOfUnloadingName?: string | null;
  portOfDeliveryName?: string | null;
  origin?: string | null;
  destination?: string | null;
  modeOfTransportationName?: TransportationMethod | null;
  portOfReceipt?: PortDto | null;
  portOfLoading?: PortDto | null;
  portOfUnloading?: PortDto | null;
  portOfDelivery?: PortDto | null;
  countryOfOrigin?: CountryDto | null;
  countryOfDestination?: CountryDto | null;
  currency?: CurrencyDto | null;
  accountingItem?: AccountingItemDto | null;
  modeOfTransportation?: ModeOfTransportationDto | null;
  client?: ContactDto | null;
  finalMileCarrier?: ContactDto | null;
  carrier?: ContactDto | null;
  commodityType?: CommodityTypeDto | null;
  created?: string | null;
  createdBy?: string | null;
  lastModified?: string | null;
  lastModifiedBy?: string | null;
  createdByUserName?: string | null;
  lastModifiedByUserName?: string | null;
  customValues?: {
    [key: string]: string | null;
  } | null;
  links?: LinkDto[] | null;
  estimatedCost?: string;
};
export type Frequency = "Daily" | "Weekly" | "Biweekly" | "Monthly" | "Other";
export type RateType = "StandardClientRate" | "ClientRate" | "CarrierRate";
export type TransportationMethod =
  | "Ocean"
  | "Rail"
  | "Ground"
  | "Air"
  | "Mail"
  | "Unknown"
  | "Pipe";
export type PortDto = {
  portId?: string | null;
  isAir?: boolean;
  isBorderCrossingPoint?: boolean;
  countryCode?: string | null;
  created?: string;
  createdBy?: string | null;
  isMyCompany?: boolean;
  lastModified?: string;
  lastModifiedBy?: string | null;
  isMail?: boolean;
  isMariTime?: boolean;
  name?: string | null;
  organizationId?: number;
  portRemarks?: string | null;
  isRail?: boolean;
  isRoad?: boolean;
  stateCode?: string | null;
  countryCodeName?: string | null;
  stateName?: string | null;
  links?: LinkDto[] | null;
};
export type CountryDto = {
  countryCode?: string | null;
  name?: string | null;
  organizationId?: number | null;
  customValues?: {
    [key: string]: string | null;
  } | null;
  links?: LinkDto[] | null;
};

export type CountryGqlDto = {
  countryCode?: string | null;
  name?: string | null;
  organizationId?: number | null;
  customValues?: ICustomValuesGQL[] | null;
  links?: LinkDto[] | null;
};

export type CurrencyDto = {
  currencyId?: number;
  created?: string;
  createdBy?: string | null;
  currencyCode?: string | null;
  currencyName?: string | null;
  decimalPlaces?: number | null;
  exchangeRateLastUpdate?: string | null;
  exchangeRateToPrimary?: number | null;
  gainLossAccountId?: number | null;
  gainLossAccountDescription?: string | null;
  isInactive?: boolean;
  lastModified?: string;
  lastModifiedBy?: string | null;
  organizationId?: number;
  symbol?: string | null;
  createdByUserName?: string | null;
  lastModifiedByUserName?: string | null;
  links?: LinkDto[] | null;
};
export type ModeOfTransportationDto = {
  modeOfTransportationId?: number | null;
  created?: string | null;
  createdBy?: string | null;
  description?: string | null;
  lastModified?: string | null;
  lastModifiedBy?: string | null;
  organizationId?: number | null;
  transportationMethod?: TransportationMethod | null;
  transportationMethodDescription?: TransportationMethodDescription | null;
  usCustomsCode?: string | null;
  createdByUserName?: string | null;
  lastModifiedByUserName?: string | null;
  customValues?: any | null;
  links?: LinkDto[] | null;
};
export type TransportationMethodDescription =
  | "Vessel"
  | "VesselAndContainerized"
  | "Barge"
  | "Rail"
  | "RailAndContainerized"
  | "Truck"
  | "TruckAndContainerized"
  | "Auto"
  | "Pedestrian"
  | "RoadAndOther"
  | "Air"
  | "AirAndContainerized"
  | "Mail"
  | "PassengerAndHandCarried"
  | "FixedTransport";
export type ServiceType =
  | "PortToDoor"
  | "DoorToDoor"
  | "DoorToPort"
  | "PortToPort";
export type TariffOptions = {
  maximum?: number | null;
  minimum?: number | null;
  rateMultiplier?: number | null;
  applyBy?: ApplyBy | null;
  unitType?: Units | null;
  ratePerType?: RatePerType | null;
  calculatedOf?: CalculatedOfTypes | null;
  ratePer?: number | null;
  rates?: number[][] | null;
  percentageOfType?: PercentageOfType;
  rateData?: RateData[] | null;
};
export type Units = "Ft" | "Vlb" | "Vkg" | "M" | "Lb" | "Kg";
export type RatePerType = "Unit" | "Range";
export type PercentageOfType = "Cost" | "Income" | "Profit";
export type RateData = {
  rateIndex?: number | null;
  rateValue?: number;
};
export const PurchaseBox = (
  trackingNumber: string,
  containerCommodities?: CreateCommodityPortalCommand[] | null,
): CreateCommodityPortalCommand => {
  return {
    uuid: uuid(),
    description: trackingNumber,
    containerCommodities: containerCommodities ?? [],
    pieces: 1,
  };
};

export type ShipmentInfo = {
  orderId?: number;
  trackingNumber?: string | null;
  orderType?: OrderTypes | string | null;
  created?: string | null;
  orderStatus?: OrderStatusDto | null;
  accountingTransactionId?: number | null;
  transactionNumber?: string | null;
  accountingTransactionStatus?: AccountingTransactionStatus | null;
  customValues?: any;
};

export const {
  useOrderCreateOrderMutation,
  useOrderGetOrdersMutation,
  useOrderGetOrdersGQLMutation,
  useOrderGetOrderMutation,
  useOrderPatchOrderMutation,
  useOrderDeleteOrderMutation,
  useOrderPutOrderMutation,
} = injectedRtkApi;
