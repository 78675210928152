import React from "react";
import {Button, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {RemoveItemModalProps} from "../pages/interfaces";

export default function RemoveItemModal({
  closeModal,
  deleteItem,
  uuid,
  itemNumber,
  onTotalChange,
}: RemoveItemModalProps) {
  return (
    <Grid
      sx={{
        py: {xs: 0, md: 1},
      }}
    >
      <Typography
        data-testid="modal-modal-title"
        variant="h3"
        component="h6"
        px={{xs: 0, md: 3}}
        mb={1}
      >
        Do you want to delete this item?
      </Typography>
      <Typography
        data-testid="modal-modal-description"
        variant="caption1"
        component="h6"
        color={"primary.dark"}
        fontWeight={500}
      >
        Make sure you want to remove Item {itemNumber} from your purchase.
      </Typography>
      <Grid container justifyContent={"space-between"} mt={3}>
        <Button
          type="button"
          variant="outlined"
          color="primary"
          sx={{flex: "0.5", mr: 2}}
          onClick={closeModal}
        >
          No
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          data-testid="modal-purchase-remove-item-confirm"
          sx={{flex: "0.5"}}
          onClick={async () => {
            await deleteItem(uuid);
            if (onTotalChange) onTotalChange();
          }}
        >
          Yes
        </Button>
      </Grid>
    </Grid>
  );
}
