import React, {useEffect, useMemo, useRef, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  IconButton,
  ImageList,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ModalForm from "../../../common/Modal/components/modalForm";
import DeletePurchaseModal from "./deletePurchaseModal";
import {
  CommodityDto,
  EntityTypes,
  OrderEntityGQLDto,
} from "../../../features/order/order-api";
import {getFormattedDate} from "../../../../utils/formatting.utils";
import {useNavigate, useParams} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import {PurchaseListCardFormProps} from "../../../ParcelShipments/ParcelShipmentInfo/pages/interfaces";
import Link from "../../../common/components/link";
import {AttachmentDto} from "../../../features/attachment/attachment-api";
import {ImageWithModal} from "../../../common/components/imageWithModal";
import ClickableChip from "../../../common/components/chip";
import {
  destructurizeGQLCustomValues,
  getCustomValue,
  stageColor,
} from "../../../../utils/helper.utils";
import PurchaseDefaultLogo from "../../../../images/PurchaseDefaultLogo.png";
import {useAppSelector} from "../../../store";

export const PurchaseListCardForm = ({
  purchase,
  parcelShipmentInfo,
  draft,
  isConsolidated,
  handleCheckbox,
  isSelected,
}: PurchaseListCardFormProps) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [expendItems, setExpendItems] = useState<boolean>(false);
  const [expendPhotos, setExpendPhotos] = useState<boolean>(false);
  const [vendor, setVendor] =
    useState<OrderEntityGQLDto | undefined | null>(null);
  const [vendorLogo, setVendorLogo] = useState<string | null>(null);

  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [boxCommodity, setBoxCommodity] =
    useState<CommodityDto | null | undefined>(null);
  const [orderCustomValues, setOrderCustomValues] = useState<any>(null);
  const [orderStatusCustomValues, setOrderStatusCustomValues] =
    useState<any>(null);

  const deliveryConfig = useAppSelector(
    (state: any) => state.organizationConfigState.modules.delivery,
  );
  const enableCustomerNotes = deliveryConfig?.enableCustomerNotes === true;

  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const navigate = useNavigate();
  const {t} = useTranslation();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const {lang} = useParams();

  const imagesList = useRef<any>(null);

  useEffect(() => setIsPageLoading(false), []);

  const invoiceId = parcelShipmentInfo?.accountingTransactionId;

  const invoiceTransactionNumber = parcelShipmentInfo?.transactionNumber;
  const draftNumber = draft?.orderNumber;

  useEffect(() => {
    if (purchase && purchase.commodities && purchase.commodities[0]) {
      setBoxCommodity(purchase.commodities[0]?.commodity);
    }

    if (purchase && purchase.orderEntities) {
      const vendor = purchase.orderEntities.find(
        (x) => x.entityType == EntityTypes.Vendor,
      );
      setVendor(vendor ?? null);
      if (
        vendor?.attachments &&
        vendor?.attachments.length > 0 &&
        vendor?.attachments[0].presignedThumbnailUri
      )
        setVendorLogo(vendor?.attachments[0].presignedThumbnailUri);
    }

    if (purchase && purchase.customValues) {
      setOrderCustomValues(destructurizeGQLCustomValues(purchase.customValues));
    }
    if (purchase && purchase.orderStatus && purchase.orderStatus.customValues) {
      setOrderStatusCustomValues(purchase.orderStatus.customValues);
    }
  }, [purchase]);

  const renderVendor = () => {
    if (vendor)
      return isDesktop ? (
        <Grid display={"flex"} whiteSpace={"nowrap"}>
          <Typography
            display={"inline-block"}
            component={"span"}
            variant="body1"
            color={"primary.dark"}
          >
            {t("purchases.purchaseCard.seller")}
          </Typography>
          <Typography
            ml={0.7}
            maxWidth={{xl: "25ch", lg: "14ch", md: "10ch"}}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
          >
            {vendor?.nonContactName || vendor?.contact?.name}
          </Typography>
        </Grid>
      ) : (
        <Grid display={"flex"} whiteSpace={"nowrap"}>
          <Typography
            component={"span"}
            variant="caption3"
            color={"primary.gray"}
            fontFamily={"Inter Regular"}
          >
            {t("purchases.purchaseCard.seller")}&nbsp;
          </Typography>
          <Typography
            component={"span"}
            variant="caption3"
            maxWidth={{sm: "40ch", xs: "10ch"}}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
            color={"primary.dark"}
            fontFamily={"Inter Regular"}
          >
            {vendor?.nonContactName || vendor?.contact?.name}
          </Typography>
        </Grid>
      );
  };

  const renderReceivedAt = (variant: any) => {
    if (orderCustomValues?.receivedAt) {
      return (
        <Grid display={"flex"} sx={{wordBreak: "break-word"}}>
          <Typography
            component={"span"}
            color={"primary.gray"}
            variant={variant}
            fontFamily={"Inter Regular"}
          >
            {t("purchases.purchaseCard.received")}&nbsp;
          </Typography>
          <Typography
            component={"span"}
            variant={variant}
            color={"primary.dark"}
            fontFamily={"Inter Regular"}
          >
            {getFormattedDate(orderCustomValues.receivedAt)}
          </Typography>
        </Grid>
      );
    } else {
      return (
        <Grid display={"flex"} sx={{wordBreak: "break-word"}}>
          <Typography
            component={"span"}
            color={"primary.gray"}
            variant={variant}
            fontFamily={"Inter Regular"}
          >
            {t("purchases.purchaseCard.notReceived")}
          </Typography>
        </Grid>
      );
    }
  };

  const vendorLogoElement = useMemo(() => {
    return isDesktop ? (
      <Grid mr={"24px"}>
        <img
          src={vendorLogo ?? PurchaseDefaultLogo}
          alt="vendor logo"
          width={"64px"}
          height={"64px"}
          loading="lazy"
          style={{borderRadius: "50%"}}
        />
      </Grid>
    ) : (
      <Grid display={"flex"} alignItems={"end"}>
        <img
          src={vendorLogo ?? PurchaseDefaultLogo}
          alt="vendor logo"
          width={"50px"}
          height={"50px"}
          loading="lazy"
          style={{borderRadius: "50%"}}
        />
      </Grid>
    );
  }, [vendorLogo]);

  return (
    <>
      {/* desktop */}
      <Grid
        container
        columns={{xs: 6, md: 12}}
        border={`1px solid ${isSelected ? "#3E80FF" : "#D8E6FB"}`}
        borderRadius={"16px"}
        p={"24px"}
        minHeight={"150px"}
        boxShadow={"0px 0px 8px rgba(15, 74, 132, 0.1)"}
        flexWrap={"nowrap"}
        display={{xs: "none", md: "flex"}}
        mt={2}
        data-testid={`purchase-item-${purchase?.trackingNumber}`}
      >
        <Grid md={5} borderRight={"1px solid #D8E6FB"}>
          <Grid container flexWrap={"nowrap"} whiteSpace={"nowrap"}>
            {vendorLogoElement}
            <Grid
              container
              flexDirection={"column"}
              justifyContent={"space-between"}
              alignItems={"start"}
              rowGap={"8px"}
              mr={"24px"}
              width={"80%"}
            >
              <Grid md>
                <ClickableChip
                  style={{
                    minHeight: "24px",
                    height: "max-content",
                    fontWeight: 500,
                    fontSize: "14px",
                    p: "3px 10px",
                    ...stageColor(orderStatusCustomValues?.portal_stage_color),
                  }}
                  clicked
                  label={
                    <Box
                      component={"div"}
                      sx={{
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                        overflow: "visible",
                        textAlign: "center",
                      }}
                    >
                      {orderStatusCustomValues?.[`portal_name_${lang}`] ||
                        purchase?.orderStatus?.orderStatusName}
                    </Box>
                  }
                />
              </Grid>
              <Grid whiteSpace={"normal"} sx={{wordBreak: "break-all"}}>
                <Typography component={"span"} variant="body1">
                  {t("purchases.purchaseCard.trackingNumber")}
                </Typography>
                <Typography
                  component={"span"}
                  variant="body1"
                  color={"primary.main"}
                  data-testid={`purchase-tracking-number-${purchase?.trackingNumber}`}
                >
                  {purchase?.trackingNumber}
                </Typography>
              </Grid>

              {renderReceivedAt("body1")}

              {renderVendor()}

              <Grid>
                <Typography
                  component={"span"}
                  variant="body1"
                  color={"primary.dark"}
                >
                  {t("purchases.purchaseCard.created")}
                </Typography>
                <Typography component={"span"} variant="body1">
                  {getFormattedDate(new Date(purchase!.created!.toString()))}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          md={8}
          flexWrap={"wrap"}
          display={{xs: "none", md: "flex"}}
          ml={3}
        >
          <Grid
            md={12}
            flexWrap={"wrap"}
            display={{xs: "none", md: "flex"}}
            alignItems={"center"}
            justifyContent={"space-between"}
            borderBottom={"1px solid #D8E6FB"}
            pb={2}
          >
            <Typography component={"span"} variant="body1">
              {t("purchases.purchaseCard.purchaseInformationHead")}
            </Typography>
            <Grid>
              {!parcelShipmentInfo?.orderId && !isConsolidated && (
                <IconButton
                  onClick={() => {
                    navigate(
                      window.location.pathname + `/${purchase?.orderId}`,
                    );
                    window.scrollTo(0, 0);
                  }}
                >
                  <EditIcon sx={{fontSize: "24px", color: "primary.dark"}} />
                </IconButton>
              )}
              {isConsolidated && !parcelShipmentInfo?.orderId && (
                <Checkbox
                  sx={{
                    color: "primary.light",
                    ml: {xs: 2, md: 0},
                    pr: 0,
                    mr: "-4px",
                  }}
                  id={`checkbox-select-purchase-${purchase?.orderId}`}
                  data-testid={`checkbox-select-purchase-${purchase?.orderId}`}
                  onChange={(_, checked) => {
                    if (handleCheckbox) {
                      handleCheckbox((prev) => {
                        const orderId = purchase?.orderId ?? 0;
                        let orderIds = [...prev];
                        if (checked) {
                          orderIds.push(orderId);
                        } else {
                          orderIds = orderIds.filter((x) => x !== orderId);
                        }
                        return orderIds;
                      });
                    }
                  }}
                  checked={isSelected}
                />
              )}
            </Grid>
          </Grid>
          {!(
            boxCommodity?.containerCommodities &&
            boxCommodity.containerCommodities.length > 0
          ) &&
          !parcelShipmentInfo?.orderId &&
          !isConsolidated ? (
            <Grid
              md={12}
              flexWrap={"wrap"}
              display={{xs: "none", md: "flex"}}
              alignItems={"center"}
              justifyContent={"space-between"}
              py={3}
            >
              <Grid>
                <Typography component={"span"} variant="body1">
                  <Link to={`../delivery/${purchase?.orderId}`}>
                    {t("purchases.purchaseCard.addYourPurchaseInfo")}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid
              md={12}
              flexWrap={"wrap"}
              display={{xs: "none", md: "flex"}}
              alignItems={"center"}
              justifyContent={"space-between"}
              borderBottom={"1px solid #D8E6FB"}
              my={2}
            >
              <Grid
                md={12}
                sx={{cursor: "pointer"}}
                display={"flex"}
                justifyContent={"space-between"}
                onClick={() => setExpendItems((prev) => !prev)}
              >
                <Typography component={"span"} variant="body1">
                  {t("purchases.purchaseCard.packageItems")}
                </Typography>
                <Typography color={"#3E80FF"}>
                  {expendItems ? (
                    <Trans
                      data-testid="shipment-hide-all-items-in-parcel"
                      i18nKey={"parcelShipments.shipmentCard.hideAll"}
                    >
                      Hide all
                    </Trans>
                  ) : (
                    <Trans
                      data-testid="shipment-show-all-items-in-parcel"
                      i18nKey={"parcelShipments.shipmentCard.showAll"}
                    >
                      Show all
                    </Trans>
                  )}
                  &nbsp;(
                  {boxCommodity ? boxCommodity.containerCommodities?.length : 0}
                  )
                </Typography>
              </Grid>
              <Grid md={12} mb={2}>
                <Collapse in={expendItems} timeout="auto">
                  {boxCommodity &&
                    boxCommodity.containerCommodities?.map((commodity) => (
                      <Grid
                        key={commodity?.commodityId}
                        mt={3}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignContent={"center"}
                      >
                        <Grid display={"flex"} alignItems={"center"}>
                          <Box
                            component={"span"}
                            border={"1px solid black"}
                            borderRadius={"50%"}
                            sx={{backgroundColor: "black"}}
                            width={"7px"}
                            height={"7px"}
                            ml={2}
                          />
                          <Typography variant="caption1" ml={1}>
                            <span
                              data-testid={`commodity-${commodity?.commodityId}-description`}
                            >{`${commodity?.description || ""}`}</span>
                          </Typography>
                        </Grid>
                        <Typography
                          data-testid={`commodity-${commodity?.commodityId}-quantity`}
                          variant="caption1"
                          color={"#6C757D"}
                          ml={1}
                        >
                          {commodity?.quantity}&nbsp;pcs
                        </Typography>
                      </Grid>
                    ))}
                </Collapse>
              </Grid>
            </Grid>
          )}

          {purchase?.attachments &&
          purchase.attachments.length &&
          purchase.attachments.length > 0 ? (
            <Grid mb={2}>
              <Box>
                <Box
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={() => setExpendPhotos((prev) => !prev)}
                  sx={{cursor: "pointer"}}
                >
                  <Grid md display={"flex"} justifyContent={"space-between"}>
                    <Typography variant="body1">
                      <Trans
                        i18nKey={"parcelShipments.shipmentDetails.photosGoods"}
                      >
                        Photos of the goods
                      </Trans>
                    </Typography>
                    &nbsp;
                    {purchase.attachments.length > 5 ? (
                      <Typography color={"#3E80FF"}>
                        {expendPhotos ? (
                          <Trans
                            data-testid="shipment-hide-all-photos"
                            i18nKey={"parcelShipments.shipmentCard.hideAll"}
                          >
                            Hide all
                          </Trans>
                        ) : (
                          <Trans
                            data-testid="shipment-show-all-photos"
                            i18nKey={"parcelShipments.shipmentCard.showAll"}
                          >
                            Show all
                          </Trans>
                        )}
                        &nbsp;({purchase.attachments.length})
                      </Typography>
                    ) : null}
                  </Grid>
                </Box>
                <Collapse
                  in={
                    expendPhotos ||
                    (purchase.attachments &&
                      purchase.attachments.length <= 5) ||
                    false
                  }
                  collapsedSize={imagesList.current?.clientHeight ?? 50 + 20}
                  timeout="auto"
                >
                  <ImageList cols={5} gap={16}>
                    {purchase.attachments.map(
                      (item: AttachmentDto, idx: number) => (
                        <ImageWithModal
                          refImg={idx === 0 ? imagesList : null}
                          key={item.attachmentId}
                          item={{
                            uri: item.presignedPreviewUri ?? "",
                            description: item?.description,
                          }}
                        />
                      ),
                    )}
                  </ImageList>
                </Collapse>
              </Box>
            </Grid>
          ) : null}

          {!isConsolidated && (
            <Grid
              display={{xs: "none", md: "flex"}}
              alignItems={"end"}
              justifyContent={"flex-end"}
              md={12}
              gap={2}
            >
              {!parcelShipmentInfo?.orderId ? (
                <>
                  {draftNumber && (
                    <Grid
                      height={"100%"}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Typography>
                        <Trans
                          i18nKey={
                            "purchases.purchaseCard.unfinishedShipRequest"
                          }
                        >
                          Unfinished Ship Request #{{draftNumber}}
                        </Trans>
                      </Typography>
                    </Grid>
                  )}
                  <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() => {
                      navigate(
                        `../delivery/${purchase?.orderId}/selectRequestType`,
                      );
                    }}
                    disabled={isPageLoading}
                    data-testid={`purchase-request-to-ship-${purchase?.trackingNumber}`}
                  >
                    {t("purchases.purchaseCard.requestToShip")}
                  </Button>
                </>
              ) : invoiceId != null &&
                invoiceTransactionNumber != null &&
                parcelShipmentInfo?.accountingTransactionStatus == "Open" ? (
                <Button
                  style={{paddingLeft: "22px", paddingRight: "22px"}}
                  type="button"
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to={`../invoices/${invoiceId}/tracking/${invoiceTransactionNumber}`}
                  disabled={isPageLoading}
                >
                  {t("invoices.payInvoice")}
                </Button>
              ) : (
                <>
                  {!getCustomValue(
                    parcelShipmentInfo?.customValues,
                    "customer_notes",
                  ) &&
                    enableCustomerNotes && (
                      <Button
                        style={{paddingLeft: "22px", paddingRight: "22px"}}
                        variant={"outlined"}
                        color={"primary"}
                        onClick={() =>
                          navigate(
                            `../parcelShipments/${parcelShipmentInfo?.orderId}?editNote=1`,
                          )
                        }
                        disabled={isPageLoading}
                      >
                        <Trans i18nKey={"purchases.purchaseCard.addNotes"}>
                          Add Notes
                        </Trans>
                      </Button>
                    )}
                  <Button
                    style={{paddingLeft: "22px", paddingRight: "22px"}}
                    variant={"outlined"}
                    color={"primary"}
                    onClick={() =>
                      navigate(
                        `../parcelShipments/${parcelShipmentInfo?.orderId}`,
                      )
                    }
                    disabled={isPageLoading}
                  >
                    {t("purchases.purchaseCard.trackPurchase")}
                  </Button>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* desktop */}

      {/* mobile */}
      <Grid
        position={"relative"}
        zIndex={50}
        mt={2}
        display={{xs: "block", md: "none"}}
        width={"calc(100vw - 2rem)"}
      >
        <Grid
          container
          flexDirection={"column"}
          border={`1px solid ${isSelected ? "#3E80FF" : "#D8E6FB"}`}
          borderRadius={"16px"}
          boxShadow={"0px 0px 8px rgba(15, 74, 132, 0.1)"}
          sx={{backgroundColor: "white"}}
          display={{xs: "block", md: "none"}}
          zIndex={10}
        >
          <Grid
            container
            columns={{xs: 12}}
            flexWrap={"nowrap"}
            justifyContent={"space-between"}
            flexDirection={"column"}
            gap={"10px"}
            p={"16px"}
          >
            <Grid xs={12}>
              <Grid
                container
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"start"}
                rowGap={"8px"}
                height={"100%"}
                flexWrap={"nowrap"}
              >
                <Grid>
                  <Typography
                    variant="body3"
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body3"
                      sx={{display: "inline-block", whiteSpace: "nowrap"}}
                    >
                      {purchase?.orderEntities?.find(
                        (oe) => oe.entityType == EntityTypes.Vendor,
                      )?.contact?.name ??
                        t("purchases.purchaseCard.expectingPackage")}
                      &nbsp;
                    </Typography>
                  </Typography>
                </Grid>
                <Grid>
                  {!parcelShipmentInfo?.orderId && !isConsolidated && (
                    <IconButton
                      sx={{padding: 0}}
                      onClick={() => {
                        navigate(
                          window.location.pathname + `/${purchase?.orderId}`,
                        );
                        window.scrollTo(0, 0);
                      }}
                    >
                      <EditIcon
                        className="edit"
                        sx={{
                          fontSize: "24px",
                          color: "primary.dark",
                        }}
                      />
                    </IconButton>
                  )}
                  {isConsolidated && (
                    <Checkbox
                      sx={{
                        color: "primary.light",
                        ml: {xs: 2, md: 0},
                        pr: 0,
                        mr: "-4px",
                      }}
                      id={`checkbox-select-purchase-${purchase?.orderId}`}
                      data-testid={`checkbox-select-purchase-${purchase?.orderId}`}
                      onChange={(_, checked) => {
                        if (handleCheckbox) {
                          handleCheckbox((prev) => {
                            const orderId = purchase?.orderId ?? 0;
                            let orderIds = [...prev];
                            if (checked) {
                              orderIds.push(orderId);
                            } else {
                              orderIds = orderIds.filter((x) => x !== orderId);
                            }
                            return orderIds;
                          });
                        }
                      }}
                      checked={isSelected}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container columns={12}>
              <Grid xs={12} sx={{overflowWrap: "break-word"}}>
                <Typography variant="body1" component={"span"}>
                  #{purchase?.trackingNumber ?? ""}
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Grid>
                <Chip
                  label={
                    orderStatusCustomValues?.[`portal_name_${lang}`] ||
                    purchase?.orderStatus?.orderStatusName
                  }
                  variant="outlined"
                  style={{
                    minHeight: "24px",
                    height: "max-content",
                    fontWeight: 500,
                    fontSize: "14px",
                    border: 0,
                    padding: "0 6px",
                    ...stageColor(orderStatusCustomValues?.portal_stage_color),
                  }}
                />
              </Grid>
            </Grid>
            <Grid xs={12} flexDirection={"row"}>
              <Grid
                container
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                height={"100%"}
                flexWrap={"wrap-reverse"}
                gap={1}
              >
                <Grid>
                  <Typography
                    className="show-details"
                    color={"primary"}
                    variant="caption2"
                    onClick={() => {
                      setShowDetails(!showDetails);
                    }}
                    sx={{cursor: "pointer"}}
                  >
                    {showDetails
                      ? t("purchases.purchaseCard.hideDetails")
                      : t("purchases.purchaseCard.showDetails")}
                  </Typography>
                </Grid>
                {!isConsolidated && (
                  <Grid
                    display={"flex"}
                    marginLeft={"auto"}
                    gap={1}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    flexWrap={"wrap-reverse"}
                  >
                    {!parcelShipmentInfo?.orderId ? (
                      <>
                        {draftNumber && (
                          <Grid
                            height={"100%"}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Typography>
                              <Trans
                                i18nKey={
                                  "purchases.purchaseCard.unfinishedShipRequest"
                                }
                              >
                                Unfinished Ship Request #{{draftNumber}}
                              </Trans>
                            </Typography>
                          </Grid>
                        )}
                        <Button
                          variant={"contained"}
                          color={"secondary"}
                          onClick={() => {
                            navigate(
                              `../delivery/${purchase?.orderId}/selectRequestType`,
                            );
                          }}
                        >
                          {t("purchases.purchaseCard.requestToShip")}
                        </Button>
                      </>
                    ) : invoiceId != null &&
                      invoiceTransactionNumber != null &&
                      parcelShipmentInfo?.accountingTransactionStatus ==
                        "Open" ? (
                      <Button
                        type="button"
                        variant="contained"
                        color="secondary"
                        component={Link}
                        to={`../invoices/${invoiceId}/tracking/${invoiceTransactionNumber}`}
                      >
                        {t("invoices.payInvoice")}
                      </Button>
                    ) : (
                      <>
                        {!getCustomValue(
                          parcelShipmentInfo?.customValues,
                          "customer_notes",
                        ) && (
                          <Button
                            style={{paddingLeft: "22px", paddingRight: "22px"}}
                            variant={"outlined"}
                            color={"primary"}
                            onClick={() =>
                              navigate(
                                `../parcelShipments/${parcelShipmentInfo?.orderId}?editNote=1`,
                              )
                            }
                            disabled={isPageLoading}
                          >
                            <Trans i18nKey={"purchases.purchaseCard.addNotes"}>
                              Add Notes
                            </Trans>
                          </Button>
                        )}
                        <Button
                          variant={"outlined"}
                          color={"primary"}
                          onClick={() =>
                            navigate(
                              `../parcelShipments/${parcelShipmentInfo?.orderId}`,
                            )
                          }
                        >
                          {t("purchases.purchaseCard.trackPurchase")}
                        </Button>
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Collapse in={showDetails} timeout="auto">
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"stretch"}
              flexWrap={"nowrap"}
              p={"0 16px 16px 16px"}
            >
              <Grid>
                {renderReceivedAt("caption3")}

                {renderVendor()}

                <Grid display={"flex"}>
                  <Typography
                    component={"span"}
                    color={"primary.gray"}
                    variant="caption3"
                    fontFamily={"Inter Regular"}
                  >
                    {t("purchases.purchaseCard.created")}&nbsp;
                  </Typography>
                  <Typography
                    component={"span"}
                    variant="caption3"
                    color={"primary.dark"}
                  >
                    {getFormattedDate(new Date(purchase!.created!.toString()))}
                  </Typography>
                </Grid>
              </Grid>
              {vendorLogoElement}
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
      {/* mobile */}

      <ModalForm
        open={open}
        setOpen={setOpen}
        customStyle={{width: "94%", padding: "32px 16px", overflow: "hidden"}}
      >
        <DeletePurchaseModal closeModal={closeModal} />
      </ModalForm>
    </>
  );
};
